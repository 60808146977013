import axios from "axios";
import md5 from "js-md5";

export default {
    namespaced: true,
    state: {
        // 指示当前页和总页数
        curPage: 0,
        totalPages: null,

        // 存储筛选条件
        areaValue: [],

        // 表格展示病人的简略信息
        patients: [],

        // 病人的详细信息
        patient: {}
    },
    actions: {
    },
    mutations: {
        /*
            病人数据分页显示流程：

            当 statistic 组件挂载时，调用 getPageNums 接口获取一个初始的总页数，然后赋值并初始化当前页数
            调用 getPatientsByMsg 获取病人数据并展示，注意 curPage 的取值

            当更改病人的筛选条件时，需要将当前页数归 0，调用 getPageNums 接口获取更改后的病人页数，然后赋值并初始化当前页数
            调用 getPatientsByMsg 接口获取当前病人数据并展示

            如果当前筛选条件不变，只获取某些数据，则只需要调用 getPatientsByMsg 接口获取当前页的数据
        */
        SHOW_PATIENTS(state, searchValue) {
            // 首先对筛选条件进行修改并保存
            if(searchValue.areaValue.length === 1) {
                state.areaValue = [...searchValue.areaValue, '', '']
            } else if(searchValue.areaValue.length === 2) {
                state.areaValue = [...searchValue.areaValue, '']
            } else if(searchValue.areaValue.length === 3) {
                state.areaValue = [...searchValue.areaValue]
            } else {
                state.areaValue = ['', '', '']
            }
            let time = new Date().getTime().toString().slice(0, 10)
            // 然后发送请求获取总页数
            axios({
                url: '/user/getPageNums',
                method: 'post',
                headers: {
                    "uid": localStorage.getItem('uid').toString(),
                    "token": md5(time + localStorage.getItem('token')),
                    "time": time,
                    "type": localStorage.getItem('userType').toString()
                },
                data: {
                    addressProvince: state.areaValue[0],
                    addressCity: state.areaValue[1],
                    addressDistrict: state.areaValue[2],
                    tumorType: searchValue.patientValue,
                }
            }).then(async response => {
                state.totalPages = response.data.data
                await axios({
                    url: '/user/getPatientsByMsg',
                    method: 'post',
                    headers: {
                        "uid": localStorage.getItem('uid').toString(),
                        "token": md5(time + localStorage.getItem('token')),
                        "time": time,
                        "type": localStorage.getItem('userType').toString()
                    },
                    data: {
                        addressProvince: state.areaValue[0],
                        addressCity: state.areaValue[1],
                        addressDistrict: state.areaValue[2],
                        tumorType: searchValue.patientValue,
                        pageNum: state.curPage.toString(),
                    }
                }).then(response => {
                    console.log(response)
                    if(response.data.code === 505) {
                        alert(response.data.msg)
                    } else {
                        state.patients = response.data.data
                    }
                }).catch(error => {
                    console.log(error)
                })
            }).catch(err => {
                console.log(err)
            })
        },

        // 翻页
        NEXT_PAGE(state, curPage) {
            state.curPage = curPage
        },
        // 页数归零
        REVERSE_CURRENTPAGE(state) {
            state.curPage = 0
        },

        GET_PATIENT_BY_ID(state, id) {
            let time = new Date().getTime().toString().slice(0, 10)
            axios({
                url: '/user/getUserById',
                method: 'post',
                headers:{
                    "uid": localStorage.getItem('uid').toString(),
                    "token": md5(time + localStorage.getItem('token')),
                    "time": time,
                    "type": localStorage.getItem('userType').toString()
                },
                data: {
                    patient_id: id
                }
            }).then(response => {
                state.patient = response.data.data
            }).catch(err => {
                console.log(err)
            })
        }
    }
}