// 引入 vue-router
import VueRouter from 'vue-router'

// 引入组件
// import Download from "@/pages/Download"
import LogInManage from "@/pages/LogInManage";
import Statistic from "@/pages/Statistic";
import UserOperation from "@/pages/UserOperation";
import Map from "@/pages/Map";
import Goods from "@/pages/Goods"
import FirmManage from "@/pages/FirmManage";
import TextEdit from "@/pages/TextEdit";
import OperationDialog from "@/pages/OperationDialog";
import PowerManage from "@/pages/PowerManage";
import ForgetPassword from "@/pages/ForgetPassword";
import HomePage from "@/pages/HomePage";
import LogIn from "@/pages/LogIn";
import UserData from "@/pages/UserData";
import MedicalRecord from "@/pages/MedicalRecord";
import NewsManage from "@/pages/NewsManage";
import MyNews from "@/pages/MyNews";
import FirmWareManage from "@/pages/FirmWareManage";

// 创建路由
export default new VueRouter({
    mode: 'history',
    routes:[
        {
            name: '/',
            path: '/',
            meta: { title: '昆仑智鼎' },
            component: LogIn
        },
        {
            name: 'app',
            path: '/app',
            meta: { title: '昆仑智鼎' },
            // component: Download
            component: () => import(/* webpackChunkName: "app1" */ '../pages/Download.vue')
        },
        {
            name: 'homepage',
            path: '/homepage',
            component: HomePage,
            // meta: { title: '爱勇者（医生和管理端）' },
            meta: { title: '昆仑智鼎' },
            children: [
                {
                    name:`loginmanage`,
                    path:'loginmanage',
                    component:LogInManage
                },
                {
                    name:'statistic',
                    path:'statistic',
                    component:Statistic
                },
                {
                    name:'useropeartion',
                    path:'useropeartion',
                    component:UserOperation
                },
                {
                    name:'map',
                    path:'map',
                    component:Map
                },
                {
                    name:'goods',
                    path:'goods',
                    component:Goods
                },
                {
                    name:'firmmanage',
                    path:'firmmanage',
                    component:FirmManage
                },
                {
                    name:'textedit',
                    path:'textedit',
                    component:TextEdit
                },
                {
                    name:'operationdialog',
                    path:'operationdialog',
                    component:OperationDialog
                },
                {
                    name:'powermanage',
                    path:'powermanage',
                    component:PowerManage
                },
                {
                    name: 'userdata',
                    path: 'userdata',
                    component: UserData,
                },
                {
                    name: 'medicalrecord',
                    path: 'medicalrecord',
                    component: MedicalRecord
                },
                {
                    name: 'newsmamage',
                    path: 'newsmamage',
                    component: NewsManage
                },
                {
                    name: 'mynews',
                    path: 'mynews',
                    component: MyNews
                },
                {
                    name: 'firmwaremanage',
                    path: 'firmwaremanage',
                    component: FirmWareManage
                }
            ]
        },
        {
            name:'fogetpassword',
            path:'/forgetpassword',
            meta: { title: '昆仑智鼎' },
            component:ForgetPassword
        }

    ]
});

