<template>
  <div>
    <el-card>
      <div slot="header">
        <span>角色权限表</span>
        <!-- <el-button style="float: right; padding: 3px 0" type="text" @click="dialogVisible = true">创建角色</el-button> -->
      </div>
      <el-table :data="roleData" border>
        <el-table-column prop="role" label="角色名称" width="80" fixed></el-table-column>
        <el-table-column prop="roleDescribe" label="角色描述" width="120" fixed></el-table-column>
        <el-table-column v-for="(permission, index) in permissions" :key="index" :label="permission.value">
          <template slot-scope="scope">
            <el-tag :type="scope.row.permissions.includes(permission.key) ? 'success' : 'danger'">
              {{ scope.row.permissions.includes(permission.key) ? '有' : '无' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.$index, scope.row)">更新角色</el-button>
            <!-- <el-button type="text" @click="handleDelete(scope.$index, scope.row)">删除角色</el-button> -->
          </template>
        </el-table-column>
      </el-table>      
    </el-card>

    <el-dialog
        title="用户信息"
        :visible.sync="dialogVisible" >
      <el-form :model="addRoleInfo">
        <el-form-item label="角色名">
          <el-input v-model="addRoleInfo.role" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="addRoleInfo.describe" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色权限">
          <el-select v-model="addRoleInfo.permission" multiple>
            <el-option v-for="item in permissions" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createRole">提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="用户信息"
        :visible.sync="updateDialogVisible" >
      <el-form :model="updateRoleInfo">
        <el-form-item label="角色名">
          <el-input v-model="updateRoleInfo.role" disabled></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="updateRoleInfo.roleDescribe"></el-input>
        </el-form-item>
        <el-form-item label="角色权限">
          <el-select v-model="updateRoleInfo.permissions" multiple>
            <el-option v-for="item in permissions" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateRole">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PowerManage",
  data() {
    return {
      // 角色信息
      roles: [],
      // 转换后的角色数据，用于表格展示
      roleData: [],
      // 权限列表
      permissions: [],

      addRoleInfo: {
        role: '',
        permission: null,
        describe: ''
      },

      updateRoleInfo: {},

      dialogVisible: false,
      updateDialogVisible: false,
      permissionMap: null
    }
  },
  methods: {
    getRoles() {
      let time = new Date().getTime().toString().slice(0, 10)
      // 获取并存储权限列表
      axios({
        url: '/role/getMyRole',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        this.roles = response.data.data
        this.convertRoleData()
      }).catch(err => {
        console.log(err)
      })
    },

    getPermissions() {
      let time = new Date().getTime().toString().slice(0, 10)
      // 获取并存储权限列表
      axios({
        url: '/role/getAllPermission',
        method: 'get',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        this.permissions = response.data.data
        this.permissionMap = new Map(this.permissions.map(item => [item.value, item.key]))
      }).catch(err => {
        console.log(err)
      })
    },

    convertRoleData() {
      // 初始化角色数据数组
      this.roleData = this.roles.map(role => {
        return {
          id: role.id,
          role: role.role,
          permissions: role.myPermission.map(permission => {
            // 使用Map查找权限的key
            return this.permissionMap.get(permission);
          }),
          roleDescribe: role.roleDescribe
        };
      });
    },

    handleDelete(index, row) {
      this.$confirm('此操作将删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let time = new Date().getTime().toString().slice(0, 10)
        axios({
          url: '/role/deleteRole',
          method: 'post',
          headers: {
            "uid": localStorage.getItem('uid').toString(),
            "token": this.$md5(time + localStorage.getItem('token')),
            "time": time,
            "type": localStorage.getItem('userType').toString()
          },
          data: {
            role: row.role,
          }
        }).then(async response => {
          if(response.data.code === 200) {
            this.$message.success('删除成功')
            this.getRoles()
          } else {
            this.$message.error(response.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },

    handleUpdate(index, row) {
      console.log(row);
      this.updateRoleInfo = row
      this.updateDialogVisible = true
    },

    createRole() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/role/createRole',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          role: this.addRoleInfo.role,
          permission: this.addRoleInfo.permission.map(Number).reduce((accumulator, currentValue) => accumulator + currentValue, 0),
          roleDescribe: this.addRoleInfo.describe
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.$message.success('修改成功')
          this.getRoles()
          this.addRoleInfo = {}
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
      this.dialogVisible = false
    },

    updateRole() {
      this.$http({
        url: this.$http.adornUrl('role/updateRole'),
        method: 'post',
        data: {
          id: this.updateRoleInfo.id,
          permission: this.updateRoleInfo.permissions.map(Number).reduce((accumulator, currentValue) => accumulator + currentValue, 0),
          roleDescribe: this.updateRoleInfo.describe
        }
      }).then(() => {
        this.$message.success('修改成功')
        this.getRoles()
        this.updateRoleInfo = {}
      }).catch(err => {
        this.$message.error(err)
      })
      this.updateDialogVisible = false
    }
  },

  mounted() {
    this.getRoles()
    this.getPermissions()
  }
}
</script>

<style scoped>

</style>