<template>
  <div id="login-container">
    <div id="login-form">
      <!-- <div id="title">用户登录</div> -->
      <h3 id="login-title">用户登录</h3>
      <el-form :model="form" :rules="rules" ref="form" label-position="left">
        <!-- 输入账号和密码 -->
        <el-form-item label="账号：" prop="account">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="测试用户手机号：">
          <el-input :disabled="true" value="11122223333"/>
        </el-form-item>
        <el-form-item label="测试用户密码：">
          <el-input :disabled="true" value="pass1234"/>
        </el-form-item>

        <!-- 自动登录、忘记密码 -->
        <el-form-item>
          <el-checkbox v-model="savePassWord" style="float: left">保存密码</el-checkbox>
          <el-button @click="pushForgetPassword" type="text" style="float: right">忘记密码</el-button>
        </el-form-item>

        <!-- 提交 -->
        <el-form-item>
          <el-button type="primary" @click="onsubmit()" style="width: 100%">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LogIn",
  data() {
    // 对输入的账号和密码进行验证
    const checkAccount = (rule, value, callback) => {
      if(!value) {
        return callback(new Error('账号不能为空'))
      } else if (value.length !== 11) {
        return callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }
    const checkPassword = (rule, value, callback) => {
      if(!value) {
        return callback(new Error('密码不能为空'))
      } else {
        callback()
      }
    }

    return {
      // 表单数据
      form: {
        account:'',
        password:''
      },

      // 对账号和密码做校验的规则
      rules: {
        account: [
          {validator: checkAccount, trigger: blur},
        ],
        password: [
          {validator: checkPassword, trigger: blur}
        ]
      },

      // 是否保存密码
      savePassWord: true
    }
  },
  methods: {
    // 登录
    onsubmit() {
      // 对输入的账号和密码做校验
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 将账号和密码发送到服务器验证
          // 获取盐来对密码加密
          axios({
            method: 'post',
            url: '/user/getSalt',
            data: {
              phone: this.form.account,
              type: 2
            }
          }).then(async response => {
            // 首先查看账号是否被锁定
            if(response.data.code === 305) {
              this.$message.error('账号被锁定')
            } else {
              // 没有被绑定则发送登录请求
              await axios({
                url: '/user/login',
                method: 'post',
                data: {
                  uid: parseInt(response.data.data.uid),
                  password: this.$md5(response.data.data.salt + this.$md5(this.form.password))
                }
              }).then(response => {
                if (response.data.code === 200) {
                  // sessionStorage 存储 uid, token
                  localStorage.setItem('isLogin', true)
                  localStorage.setItem('uid', response.data.data.uid)
                  localStorage.setItem('token', response.data.data.token)
                  localStorage.setItem('phone', response.data.data.phone)
                  localStorage.setItem('userType', response.data.data.userType)
                  localStorage.setItem('permissions', response.data.data.permissions)
                  this.$router.push({
                    name: 'homepage'
                  })
                } else {
                  this.$message.error('账号或密码错误')
                }
              }).catch(err => {
                console.log(err)
              })
            }
          }).catch(err => {
            console.log(err)
          })
          // 验证成功后确认是否设置保存密码
          if(this.savePassWord) {
            this.setCookie(this.form.account, this.form.password, 7)

          } else {
            this.setCookie('', '', -1)
          }
        } else {
          return false
        }
      })
    },

    // 路由
    pushForgetPassword() {
      this.$router.push({
        name: 'fogetpassword'
      })
    },

    // 设置cookie
    setCookie(account, password, exdays) {
      const exdate = new Date();// 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
      // 字符串拼接cookie
      window.document.cookie = 'account' + '=' + account + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'password' + '=' + password + ';path=/;expires=' + exdate.toGMTString()
    },
    // 读取cookie 将用户名和密码回显到input框中
    getCookie() {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ');// 这里显示的格式需要切割一下自己可输出看下
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=');// 再次切割
          // 判断查找相对应的值
          if (arr2[0] === 'account') {
            this.form.account = arr2[1]// 保存到保存数据的地方
          } else if (arr2[0] === 'password') {
            this.form.password = arr2[1]
          }
        }
      }
    },
  },
  mounted() {
    // document.title = '爱勇者（医生和管理端）'
    document.title = '昆仑智鼎'
    this.getCookie()
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

#container {
  margin-top: 10%;
  padding: 10px;
  display: flex;
  justify-content: center;
}

#loginWrapper {
  background-color: aliceblue;
  width: 30%;
  border-radius: 15px;
  padding: 0 20px;
}

#title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 100px;
}

#login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #2d3a4b;
}
#login-form {
  position: absolute;
  left: 0;
  right: 0;
  width: 520px;
  max-width: 100%;
  padding: 35px 35px 15px 35px;
  margin: 120px auto;
}

#login-title {
  font-size: 26px;
  font-weight: 400;
  color: #eee;
  margin: 0px auto 40px auto;
  text-align: center;
}

.input >>> .el-input__inner {
  background-color: rgba(0, 0, 0, 0.1);
  color: #eee;
  border: rgba(255, 255, 255, 0.1);
}

</style>