
// 模块化
// 登录者的信息
import axios from "axios";
import md5 from 'js-md5';

export default {
    namespaced: true,
    actions: {

    },
    mutations: {

        UPDATE_USER(state, value) {
            let time = new Date().getTime().toString().slice(0, 10)
            axios({
                url: '/user/updateMsg',
                method: 'post',
                data: {
                    username: value.username,
                    birthday: value.birthday,
                    gender: value.gender,
                    addressProvince: value.address[0],
                    addressCity: value.address[1],
                    addressDistrict: value.address[2],
                    addressDetail: value.addressDetail,
                    // hospital: '',
                    // tumorType: '',
                    emergencyContactName: value.emergencyContactName,
                    emergencyContactPhone: value.emergencyContactPhone,
                    // workUnit: '',
                    // workOffice: '',
                    // skill: '',
                    // assistantName: '',
                },
                headers: {
                    uid: localStorage.getItem('uid'),
                    token: md5(time + localStorage.getItem('token')),
                    time: time,
                    type: localStorage.getItem('userType').toString()
                }
            }).then(response => {
                if(response.data.code === 200) {
                    console.log(response)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        GET_MY_INFO(state) {
            let time = new Date().getTime().toString().slice(0, 10)
            axios({
                url: '/user/getMyMsg',
                method: 'post',
                headers: {
                    uid: localStorage.getItem('uid'),
                    token: md5(time + localStorage.getItem('token')),
                    time: time,
                    type: localStorage.getItem('userType').toString()
                }
            }).then( response => {
                state.userData = response.data.data
            }).catch( err => {
                console.log(err)
            })
        }
    },
    state: {
        userData: {}
    }
}
