export function checkPassword(inputString) {
  if (!inputString) {
    return '密码不能为空'
  }
  if ( inputString.length < 6 || inputString.length > 16) {
    return '密码长度需要在6-16之间'
  }
  // 初始化计数器  
  let hasDigit = false;  
  let hasUpperCase = false;  
  let hasLowerCase = false;  
  let hasSpecialChar = false;  
  
  // 正则表达式匹配各类字符  
  const digitRegex = /\d/;  
  const upperCaseRegex = /[A-Z]/;  
  const lowerCaseRegex = /[a-z]/;  
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // 示例特殊字符  
  
  hasDigit = digitRegex.test(inputString);  
  hasUpperCase = upperCaseRegex.test(inputString);  
  hasLowerCase = lowerCaseRegex.test(inputString);  
  hasSpecialChar = specialCharRegex.test(inputString);  
  // 判断是否包含两种及以上类型的字符  
  if ((hasDigit + hasUpperCase + hasLowerCase + hasSpecialChar) < 2) {
    return '密码需要包含数字、小写字母、大写字母和特征字符的两种即以上'
  }

  return '';  
}



export function checkPhone(inputString) {
  if (!inputString) {
    return '手机号不能为空'
  }
  if (inputString.length != 11) {
    return '手机号必须为11位'
  }
  const regex = /^\d+$/;
  if (!regex.test(inputString)) {
    return '手机号必须全部为数字'
  }
  return ''
}