<template>
  <div>
    <el-card>
      <el-select v-model="newsState" placeholder="请选择待审批资讯类型" @change="getNews">
        <el-option
          v-for="type in newsType"
          :key="type.value"
          :value="type.value"
          :label="type.label">
        </el-option>
      </el-select>
    </el-card>
    <el-card style="margin-top: 30px">
      <!-- 资讯列表 -->
      <el-table :data="newsList" border style="margin-top: 15px">
        <el-table-column label="资讯标题" prop="title" align="center" width="180px"></el-table-column>
        <el-table-column label="资讯缩略图" align="center" width="120px">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.pictureUrl"
              style="height: 90px"
              fit="contain"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="资讯链接">
          <template slot-scope="scope">
            <a :href="scope.row.webUrl">{{ scope.row.webUrl }}</a>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180px">
          <template slot-scope="scope">
            <el-button v-if="newsState === 0 || newsState === 3" type="text" @click="approve(scope.row)">审核通过</el-button>
            <el-button v-if="newsState === 0 || newsState === 1" type="text" @click="reject(scope.row)" style="color: red">审核不通过</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handlePageChange"
        background
        layout="total, prev, pager, next"
        :current-page="curPage"
        :page-count="totalPage">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "NewsManage",
  data() {
    return {
      newsState: 0,
      newsType: [
      {
        value: 0,
        label: "待审核资讯"
      }, 
      {
        value: 1,
        label: "已发布资讯"
      },
      {
        value: 3,
        label: "审核不通过/下架资讯"
      }
      ],
      newsList: [],
      curPage: 0,
      totalPage: 1
    }
  },
  methods: {
    getNews() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/news/newsPage',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          newsState: this.newsState
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.totalPage = response.data.data
        }
        await axios({
          url: '/news/getNewsByOperator',
          method: 'post',
          headers: {
            "uid": localStorage.getItem('uid').toString(),
            "token": this.$md5(time + localStorage.getItem('token')),
            "time": time,
            "type": localStorage.getItem('userType').toString()
          },
          data: {
            pageNum: this.curPage,
            newsState: this.newsState
          }
        }).then(res => {
          if(res.data.code === 200) {
            this.newsList = []
            res.data.data.map(news => {
              this.newsList.push({
                id: news.id,
                title: news.title,
                pictureUrl: news.pictureUrl,
                webUrl: news.webUrl
              })
            })
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },

    approve(row) {
      let time = new Date().getTime().toString().slice(0, 10)
      this.$confirm('确认同意？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url: '/news/updateNewsState',
          method: 'post',
          headers: {
            "uid": localStorage.getItem('uid').toString(),
            "token": this.$md5(time + localStorage.getItem('token')),
            "time": time,
            "type": localStorage.getItem('userType').toString()
          },
          data: {
            id: row.id,
            newsState: 1
          }          
        }).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: "success",
              message: "审批通过"
            })
            this.getNews()
          }
        })
      })
    },
    reject(row) {
      let time = new Date().getTime().toString().slice(0, 10)
      this.$confirm('确认拒绝？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url: '/news/updateNewsState',
          method: 'post',
          headers: {
            "uid": localStorage.getItem('uid').toString(),
            "token": this.$md5(time + localStorage.getItem('token')),
            "time": time,
            "type": localStorage.getItem('userType').toString()
          },
          data: {
            id: row.id,
            newsState: 3
          }
        }).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: "success",
              message: "审批通过"
            })
            this.getNews()
          }
        })
      })
    },
    handlePageChange(curPage) {
      let time = new Date().getTime().toString().slice(0, 10)
      this.curPage = curPage
      axios({
        url: '/news/getNewsByOperator',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          pageNum: this.curPage,
          newsState: this.newsState
        }
      }).then(res => {
        if(res.data.code === 200) {
          res.data.data.map(news => {
            this.newsList.push({
              id: news.id,
              title: news.title,
              pictureUrl: news.pictureUrl,
              webUrl: news.webUrl
            })
          })
        }
      })
    }
  },
  mounted() {
    this.getNews()
  }
}
</script>

<style scoped>

</style>