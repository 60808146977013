<template>
  <div>
    <el-card>
      <el-form inline>
        <el-form-item label="用户id: " >
          <el-input v-model="searchValue.id" placeholder="请输入搜索内容" clearable style="width: 180px"></el-input>
        </el-form-item>
        <el-form-item label="用户名称: ">
          <el-input v-model="searchValue.name" placeholder="请输入搜索内容" clearable style="width: 180px"></el-input>
        </el-form-item>
        <!-- <el-form-item label="角色名称: ">
          <el-input v-model="searchValue.role" placeholder="请输入搜索内容" clearable style="width: 180px"></el-input>
        </el-form-item> -->
        <el-form-item style="float: right">
          <el-button @click="searchUser" type="primary">查询用户</el-button>
          <el-button @click="dialogVisible=true" type="primary">添加用户</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog
        title="用户信息"
        :visible.sync="dialogVisible" >
      <el-form :model="addUserInfo" :rules="rules" ref="createUserRuleForm">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="addUserInfo.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addUserInfo.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="password">
          <el-input v-model="addUserInfo.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户类型" prop="userType">
          <el-select v-model="addUserInfo.userType">
            <el-option v-for="item in userTypes" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户性别" prop="gender">
          <el-select v-model="addUserInfo.gender">
            <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户生日">
          <el-date-picker type="date" v-model="addUserInfo.birthday" value-format="yyyy年MM月dd日"></el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="用户角色">
          <el-select v-model="addUserInfo.roleId">
            <el-option v-for="item in roles" :key="item.id" :label="item.role" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="用户额外权限">
          <el-select v-model="addUserInfo.permission" multiple>
            <el-option v-for="item in permission" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
    </el-dialog>

    <el-card style="margin-top: 10px;">
      <el-table v-bind:data="userInfos" border>
        <!-- <el-table-column type="index" width="50" fixed></el-table-column> -->
        <el-table-column label="用户ID" prop="uid" width="120" align="center"></el-table-column>
        <el-table-column label="用户姓名" prop="username" width="180" align="center"></el-table-column>
        <el-table-column label="性別" prop="gender" width="50" align="center"></el-table-column>
        <el-table-column label="用户类型" prop="userTypeStr" width="120" align="center"></el-table-column>
        <!-- <el-table-column label="角色" prop="role" width="120" align="center"></el-table-column> -->
        <el-table-column label="额外权限">
          <template slot-scope="scope">
            {{ scope.row.list.join('；') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="getDetailInfo(scope.$index, scope.row)">详细信息</el-button>
            <!-- <el-button type="text" v-if="!(scope.row.userType === 1 || scope.row.userType === 2)" @click="setRole(scope.$index, scope.row)">设置角色</el-button> -->
            <el-button type="text" v-if="!(scope.row.userType === 1 || scope.row.userType === 2)" @click="setPermission(scope.$index, scope.row)">设置权限</el-button>
            <el-button type="text" v-if="scope.row.creatorId === null || scope.row.creatorId === 0" @click="handleDelete(scope.$index, scope.row)">删除用户</el-button>
          </template>
        </el-table-column>
      </el-table>      
    </el-card>

    <el-dialog
      title="设置用户角色"
      :visible.sync="setRoleVisiable">
      <el-form :model="setUserInfo">
        <el-form-item label="用户角色">
          <el-select v-model="setUserInfo.roleId" clearable>
            <el-option v-for="item in roles" :key="item.id" :label="item.role" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleVisiable = false">取 消</el-button>
        <el-button type="primary" @click="updateUserRole">提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="设置用户额外权限"
        :visible.sync="setPermissionVisiable">
      <el-form :model="setUserInfo">
        <el-form-item label="用户权限">
          <el-select v-model="setUserInfo.permissions" multiple clearable>
            <el-option v-for="item in permission" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setPermissionVisiable = false">取 消</el-button>
        <el-button type="primary" @click="updateUserPermission">提 交</el-button>
      </span>
    </el-dialog>

    <el-drawer title="用户信息" :visible.sync="drawerVisible">
      <el-descriptions title="基本信息" :column="3" border style="margin-block-start: 10px;">
        <el-descriptions-item  label="用户名">{{ detailUser.username }}</el-descriptions-item>
        <el-descriptions-item  label="生日">{{ detailUser.birthday }}</el-descriptions-item>
        <el-descriptions-item  label="性别">{{ detailUser.gender }}</el-descriptions-item>
        <el-descriptions-item  label="所在地区">{{ detailUser.address }}</el-descriptions-item>
        <el-descriptions-item  label="所在医院">{{ detailUser.hospital }}</el-descriptions-item>
        <el-descriptions-item  label="肿瘤类型">{{ detailUser.tumorType }}</el-descriptions-item>
        <el-descriptions-item  label="紧急联系人">{{ detailUser.emergencyContactName }}</el-descriptions-item>
        <el-descriptions-item  label="用户类型">{{ detailUser.userTypeStr }}</el-descriptions-item>
        <!-- <el-descriptions-item  label="角色">{{ detailUser.role }}</el-descriptions-item> -->
      </el-descriptions>
      <!-- 权限列表 -->
      <el-descriptions title="额外权限" :column="1" border style="margin: 10px;">
        <el-descriptions-item v-for="perm in detailUser.list" :key="perm">
          <el-tag>{{ perm }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </el-drawer>
  </div>

</template>

<script>
import axios from "axios";
import {checkPassword, checkPhone} from '../util/passwordUtil'

export default {
  name: "UserOperation",
  data() {
    // 对更新信息的紧急联系人电话进行验证
    const checkPhoneStr = (rule, value, callback) => {
      let res = checkPhone(value)
      if (res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }

    // 保证修改后的密码不为空
    const checkPasswordStr = (rule, value, callback) => {
      let res = checkPassword(value)
      if(res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }

    return {
      searchValue: {
        id: null,
        name: null,
        role: ''
      },

      dialogVisible: false,
      drawerVisible: false,
      setRoleVisiable: false,
      setPermissionVisiable: false,

      permission: [],

      userTypes: [
        {value: 1, label: '病人', disabled: false},
        {value: 2, label: '家属', disabled: false},
        {value: 3, label: '医生', disabled: false},
        {value: 4, label: '企业人员', disabled: false},
        {value: 5, label: '超级管理员', disabled: false}
      ],

      gender: [
        {value: '男', label: '男'},
        {value: '女', label: '女'}
      ],

      userInfos: [],

      addUserInfo: {
        phone: null,
        username: null,
        password: null,
        userType: null,
        birthday: null,
        gender: null,
        roleId: null,
        permission: null
      },
      
      detailUser: {},

      setUserInfo: {
        uid: null,
        roleId: null,
        permissions: []
      },

      // 角色信息
      roles: [],

      rules: {
        phone: [
          {validator: checkPhoneStr, trigger: blur}
        ],
        username: [
          {required: true, message: '用户名不能为空', trigger: blur}
        ],
        password: [
          {validator: checkPasswordStr, trigger: blur}
        ],
        gender: [
          {required: true, message: '性别不能为空', trigger: blur}
        ],
        userType: [
          {required: true, message: '用户类型不能为空', trigger: blur}
        ],
      }
    }
  },
  computed: {
    users: {
      get() {
        return this.$store.state.users;
      },
      set(value) {
        console.log(value)
      }
    },
  },
  methods: {
    searchUser() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/user/queryUser',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          id: this.searchValue.id || 0,
          name: this.searchValue.name,
          role: this.searchValue.role
        }
      }).then(async response => {
        this.userInfos = response.data.data
      }).catch(err => {
        this.$message.error(err)
      })
    },
    submit() {
      this.$refs['createUserRuleForm'].validate((valid) => {
        if(valid) {
          let time = new Date().getTime().toString().slice(0, 10)
          axios({
            url: '/user/createUser',
            method: 'post',
            headers: {
              "uid": localStorage.getItem('uid').toString(),
              "token": this.$md5(time + localStorage.getItem('token')),
              "time": time,
              "type": localStorage.getItem('userType').toString()
            },
            data: {
              phone: this.addUserInfo.phone,
              username: this.addUserInfo.username,
              password: this.addUserInfo.password,
              userType: this.addUserInfo.userType,
              birthday: this.addUserInfo.birthday,
              gender: this.addUserInfo.gender,
              roleId: this.addUserInfo.roleId,
              permission: this.addUserInfo.permission.map(Number).reduce((accumulator, currentValue) => accumulator + currentValue, 0)   
            }
          }).then(async response => {
            if(response.data.code === 200) {
              this.$message.success('修改成功')
              this.searchUser()
              this.addUserInfo = {}
              this.dialogVisible = false
            } else {
              this.$message.error(response.data.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },

    handleDelete(index, row) {
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let time = new Date().getTime().toString().slice(0, 10)
        axios({
          url: 'user/managerDelete',
          method: 'post',
          headers: {
            "uid": localStorage.getItem('uid').toString(),
            "token": this.$md5(time + localStorage.getItem('token')),
            "time": time,
            "type": localStorage.getItem('userType').toString()
          },
          data: {
            uid: row.uid,
          }
        }).then(async response => {
          if(response.data.code === 200) {
            this.$message.success('删除成功')
            this.searchUser()
          } else {
            this.$message.error(response.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },

    getDetailInfo(index, row) {
      this.detailUser = row
      this.detailUser.address = row.addressProvince + row.addressCity + row.addressDistrict + row.addressDetail
      this.drawerVisible = true
    },

    setRole(index, row) {
      this.setUserInfo.uid = row.uid;
      if (row.roleId !== 0) { 
        this.setUserInfo.roleId = row.roleId
      }
      this.setRoleVisiable = true;
    },

    setPermission(index, row) {
      this.setUserInfo.uid = row.uid;
      this.setUserInfo.permissions = row.list.map(p => this.permissionMap.get(p))
      console.log(this.setUserInfo);
      this.setPermissionVisiable = true;
    },

    updateUserRole() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/user/updateUserRole',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          uid: this.setUserInfo.uid,
          roleId: this.setUserInfo.roleId || 0
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.searchUser()
          this.setUserInfo = {}
          this.$message.success("修改成功")
          this.setRoleVisiable = false
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })   
    },

    updateUserPermission() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/user/updateUserPermission',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          uid: this.setUserInfo.uid,
          permission: this.setUserInfo.permissions.map(Number).reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.searchUser()
          this.setUserInfo = {}
          this.$message.success("修改成功")
          this.setPermissionVisiable = false
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })   
    },

    getRoles() {
      let time = new Date().getTime().toString().slice(0, 10)
      // 获取并存储权限列表
      axios({
        url: '/role/getMyRole',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        this.roles = response.data.data
      }).catch(err => {
        console.log(err)
      })
    },

    getPermissions() {
      let time = new Date().getTime().toString().slice(0, 10)
      // 获取并存储权限列表
      axios({
        url: '/role/getAllPermission',
        method: 'get',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        if (response.data.code === 200) {
          this.permission = response.data.data
          this.permissionMap = new Map(this.permission.map(item => [item.value, item.key]))
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })      
    }
  },
  mounted() {
    let userType = localStorage.getItem('userType')
    console.log(this.userTypes)
    this.userTypes.forEach(item => {
      if (item.value > userType) {
        item.disabled = true
      }
    })
    console.log(this.userTypes)
    // 获取所有权限
    this.getPermissions()
    // 查询用户
    this.searchUser()
    // 获取角色
    this.getRoles()
  }
}
</script>

<style scoped>
#drawerBody {
  margin-left: 20px;
}
.user-info-item {
  margin-bottom: 10px;
}
.info-title {
  font-weight: bold;
}
.permissions ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>