// import Vue from 'vue'
import axios from 'axios'
import merge from 'lodash/merge'
import md5 from 'js-md5'

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  var time = Date.parse(new Date()) / 1000
  if (localStorage.getItem('isLogin')) {
    config.headers.token = md5(time + localStorage.getItem('token'))
    config.headers.uid = localStorage.getItem('uid')
    config.headers.time = time
    config.headers.type = localStorage.getItem('userType')
  }
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data && response.data.code === 304) { // 304, token失效
      localStorage.setItem('isLogin', false)
      this.$router.push('/')
  } else if (response.data && response.data.code === 200) {
    return response.data
  } else if (response.data.msg) {
    return Promise.reject(new Error(response.data.msg));
  } else if (response.data) {
    return Promise.reject(new Error(response.data));
  } else {
    return Promise.reject(new Error(response));
  }
}, error => {
  return Promise.reject(error);
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = actionName => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return '/braintumor-api/' + actionName
  return '/braintumor-api/' + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {}
  console.log(merge(defaults, params))
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}) => {
  return JSON.stringify(data)
}

export default http
