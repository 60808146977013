<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import LogIn from './pages/LogIn.vue';
import HomePage from "@/pages/HomePage";
import axios from "axios";
import {mapActions} from "vuex";



axios.defaults.baseURL = 'https://www.tripot.cn/braintumor-api'
// axios.defaults.baseURL = 'https://localhost:8081/braintumor-api'
axios.defaults.timeout = 10000

export default {
  name: 'App',
  components: {
    LogIn: LogIn,
    HomePage: HomePage
  },
  methods: {
    ...mapActions('loginUser', ['login'])
  },
  created() {
    /*
    // 添加事件监听，在浏览器刷新前 uid
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem('uid', this.$store.state.loginUser.userData.uid)
      sessionStorage.setItem('token', this.$store.state.loginUser.userData.token)
      sessionStorage.setItem('time', this.$store.state.loginUser.userData.time)
    })
    if(sessionStorage.getItem('uid')) {
      console.log('读取')
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/user/getMyMsg',
        method: 'post',
        headers: {
          uid: sessionStorage.getItem('uid'),
          token: this.$md5(time + sessionStorage.getItem('token')),
          time: time
        }
      }).then( response => {
        this.login(response.data.data)
        console.log(this.$store.state.loginUser.userData)
      }).catch( err => {
        console.log(err)
      })
    }

     */
  },
}
</script>

<style>
#app {
}
</style>
