<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 100px">
      <el-col :span="12">
        <el-card>
          <el-descriptions title="个人信息" :column="1" border>
            <template slot="extra">
              <el-button type="primary" @click="changeUserPassword">修改密码</el-button>
              <el-button type="primary" @click="changeUserData">修改信息</el-button>
            </template>

            <!-- <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-picture-outline"></i>
                头像
              </template>
              <el-image :src="imgUrl" :preview-src-list="previewImgUrl"></el-image>
            </el-descriptions-item> -->

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                用户名
              </template>
              {{userData.username}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-mobile-phone"></i>
                手机号
              </template>
              {{phone}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-male"></i>
                性别
              </template>
              {{userData.gender}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                出生日期
              </template>
              {{userData.birthday}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>
                住址
              </template>
              {{address}}
            </el-descriptions-item>
          </el-descriptions>         
        </el-card>

      </el-col>
    </el-row>

    <el-dialog title="修改信息" :visible.sync="dialogVisible" width="30%">
      <el-form :model="newUserData" :rules="rules" ref="ruleForm">
        <el-form-item label="用户名：" prop="username">
          <el-input v-model="newUserData.username"></el-input>
        </el-form-item>
        <el-form-item label="生日：">
          <el-date-picker type="date" v-model="newUserData.birthday" value-format="yyyy年MM月dd日"></el-date-picker>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-select v-model="newUserData.gender">
            <el-option
              v-for="option in genderOptions"
              :value="option.value"
              :key="option.value"
              :label="option.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省市区：" prop="address">
          <el-cascader v-model="newUserData.address" :options="areaData"></el-cascader>
        </el-form-item>
        <el-form-item label="详细住址：">
          <el-input v-model="newUserData.addressDetail"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人姓名：" prop="emergencyContactName">
          <el-input v-model="newUserData.emergencyContactName"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人电话：" prop="emergencyContactPhone">
          <el-input v-model="newUserData.emergencyContactPhone"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUserData">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改信息" :visible.sync="updatePasswordDialogVisible" width="30%">
      <el-form :model="updatePasswordData" :rules="updatePasswordRules" ref="updatePasswordRuleForm">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="updatePasswordData.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="updatePasswordData.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newVerifyPassword">
          <el-input v-model="updatePasswordData.newVerifyPassword"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="updatePasswordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUserPassword">确 定</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import {mapMutations, mapState} from "vuex";
import {checkPassword, checkPhone} from '../util/passwordUtil'
import areaData from '../statistic/pca-code.json'

export default {
  name: "UserData",
  data() {
    // 对更新信息的紧急联系人电话进行验证
    const checkPhoneStr = (rule, value, callback) => {
      let res = checkPhone(value)
      if (res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }

    // 保证修改后的密码不为空
    const checkNewPassword = (rule, value, callback) => {
      let res = checkPassword(value)
      if(res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }

    // 保证两次密码输入一致
    const checkVerifyPassword = (rule, value, callback) => {
      let res = checkPassword(value)
      if (value !== this.updatePasswordData.newPassword) {
        return callback(new Error('两次密码输入不一致'))
      } else if(res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }

    return {
      uid: localStorage.getItem('uid'),
      phone: localStorage.getItem('phone'),

      // 更新对话框的开启关闭
      dialogVisible: false,
      updatePasswordDialogVisible: false,

      areaData: areaData,
      genderOptions: [
        {value: '男', label: '男'},
        {value: '女', label: '女'}
      ],

      // 用于存储更新的病人信息
      newUserData: {
        username: '',
        birthday: '',
        gender: '',
        address: [],
        addressDetail: '',
        emergencyContactName: '',
        emergencyContactPhone: ''
      },

      updatePasswordData: {
        newPassword: '',
        newVerifyPassword: '',
        oldPassword: '',
      },

      // 提交表单验证
      rules: {
        username: [
          {required: true, message: '用户名不能为空', trigger: blur}
        ],
        gender: [
          {required: true, message: '性别不能为空', trigger: blur}
        ],
        address: [
          {required: true, message: '请选择所在省市区', trigger: blur}
        ],
        emergencyContactName: [
          {required: true, message: '紧急联系人姓名不能为空', trigger: blur}
        ],
        emergencyContactPhone: [
          {validator: checkPhoneStr, trigger: blur}
        ]
      },
      updatePasswordRules: {
        oldPassword: [
          {required: true, message: '旧密码不能为空', trigger: blur}
        ],
        newPassword: [
          {validator: checkNewPassword, trigger: blur}
        ],
        newVerifyPassword: [
          {validator: checkVerifyPassword, trigger: blur}
        ]
      }
    }
  },
  computed: {
    ...mapState('loginUser', ['userData']),
    imgUrl() {
      return 'https://braintumor.oss-cn-hangzhou.aliyuncs.com/avatar/avatar_' + this.uid.toString() + '.png?x-oss-process=image/resize,m_fill,h_100,w_100'
    },
    previewImgUrl() {
      return ['https://braintumor.oss-cn-hangzhou.aliyuncs.com/avatar/avatar_' + this.uid.toString() + '.png']
    },
    address() {
      return this.userData.addressProvince + this.userData.addressCity + this.userData.addressDistrict + this.userData.addressDetail
    }
  },
  methods: {
    ...mapMutations('loginUser', ['UPDATE_USER', 'GET_MY_INFO']),

    changeUserPassword() {
      this.updatePasswordDialogVisible = true
    },

    changeUserData() {
      this.newUserData = this.userData
      this.newUserData.address = [this.userData.addressProvince, this.userData.addressCity, this.userData.addressDistrict]
      console.log(this.newUserData);
      this.dialogVisible = true
    },
    updateUserData() {
      // 首先验证更新后的数据是否满足表单验证
      this.$refs['ruleForm'].validate((valid) => {
        if(valid) {
          this.UPDATE_USER(this.newUserData)

          this.$message({
            message: '提交成功',
            type: "success"
          })
          this.GET_MY_INFO()
          this.dialogVisible = false
        } else {
          this.$message({
            message: '请确认更新的信息'
          })
        }
      })
    },
    updateUserPassword() {
      this.$refs['updatePasswordRuleForm'].validate((valid) => {
        if(valid) {
          this.$http({
            url: this.$http.adornUrl('user/updatePassword'),
            method: 'post',
            data: {
              'newPassword': this.$md5(this.updatePasswordData.newPassword),
              'oldPassword': this.$md5(this.updatePasswordData.oldPassword)
            }
          }).then(() => {
            this.$message({
              message: '更新成功',
              type: 'success'
            })
            this.updatePasswordDialogVisible = false
          }).catch(err => {
            this.$message.error(err)
          })
        }
      })

    }
  },
  mounted() {
    this.GET_MY_INFO()
  }
}
</script>

<style scoped>
#data {
  border: solid #DCDFE6;
  padding: 40px 20px;
  background-color: aliceblue;
}
</style>