<template>
  <el-card>
    <el-table v-bind:data="lockedUsers" border height="250px">
      <el-table-column type="index" width="50" fixed></el-table-column>
      <el-table-column label="用户ID" prop="uid"></el-table-column>
      <el-table-column label="用户姓名" prop="username"></el-table-column>
      <el-table-column label="用户账号" prop="uAccount"></el-table-column>
      <el-table-column label="用户密码" prop="uPassword"></el-table-column>
      <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="unLock(scope.$index, scope.row)">解锁用户</el-button>
          </template>
        </el-table-column>
    </el-table>    
  </el-card>

</template>

<script>
// import {mapState} from "vuex";
import axios from "axios";

export default {
  name: "LogInManage",
  data() {
    return {
      lockedUsers: [],
    }
  },
  computed: {
  },
  methods: {
    getLockedUsers() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/user/getFrozenUser',
        method: 'get',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.lockedUsers = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    unLock(index, row) {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/user/unFrozenUser',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          id: row.uid   
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.$message.success('解锁成功')
          this.getLockedUsers()
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.getLockedUsers()
  }
}
</script>

<style scoped>

</style>