<template>
  <div>
    <el-card>
      <el-form inline>
        <el-form-item label="请选择时间：">
          <el-date-picker
            v-model="dateAndTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期" 
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchLogs">查询</el-button>
        </el-form-item>
      </el-form>      
    </el-card>

    <el-card style="margin-top: 10px;">
      <div>
        <el-button type="danger" style="float: right" @click="deleteDialog">清理日志</el-button>
        <el-table :data="dialogData" border>
          <el-table-column prop="id" label="日志id"></el-table-column>
          <el-table-column prop="uid" label="上传者id"></el-table-column>
          <el-table-column prop="errorCode" label="错误代码"></el-table-column>
          <el-table-column prop="time" label="上传时间"></el-table-column>
          <el-table-column prop="errorMessage" label="错误信息"></el-table-column>
          <el-table-column prop="postUrl" label="请求接口地址"></el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          background
          layout="total, prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-count="totalPages">
        </el-pagination>
      </div>      
    </el-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OperationDialog",
  data() {
    return {
      dateAndTime:['', ''],
      dialogData: [],

      currentPage: 1,
      pageSize: 10,
      totalPages: 0
    }
  },
  methods: {
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getLog()
    },

    getLogPage() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/log/getPageNum',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          startTime: this.dateAndTime[0],
          endTime: this.dateAndTime[1]
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.totalPages = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    
    getLog() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/log/getLog',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          startTime: this.dateAndTime[0],
          endTime: this.dateAndTime[1],
          pageNum: this.currentPage
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.dialogData = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    searchLogs() {
      this.getLogPage()
      this.currentPage = 1
      this.getLog()
    },

    deleteDialog() {
      this.$confirm('此操作将删除90天前的日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let time = new Date().getTime().toString().slice(0, 10)
        axios({
          url: 'log/deleteOldLog',
          method: 'post',
          headers: {
            "uid": localStorage.getItem('uid').toString(),
            "token": this.$md5(time + localStorage.getItem('token')),
            "time": time,
            "type": localStorage.getItem('userType').toString()
          }
        }).then(async response => {
          if(response.data.code === 200) {
            this.$message.success('删除成功')
            this.searchLogs()
          } else {
            this.$message.error(response.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    }
  },
  mounted() {
    this.searchLogs()
  }
}
</script>

<style scoped>

</style>