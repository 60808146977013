<template>
  <el-container>
    <!-- 头部 -->
    <el-header id="header" class="flex-container">
      <!-- <h1 class="flex-item">爱勇者（医生和管理端）</h1> -->
      <h1 class="flex-item">昆仑智鼎</h1>
      <div class="flex-item-group">
      <div>  V1.0.0    </div>
      <el-link type="primary" @click="download">下载客户端</el-link>
      <el-link type="danger" @click="logOut">退出登录</el-link>
      </div>
    </el-header>

    <!-- 内容 -->
    <el-container>
      <!-- 侧边导航栏 -->
      <el-aside width="200px" style="height: 100vh;">
        <AsideMenu></AsideMenu>
      </el-aside>

      <!-- 主页 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AsideMenu from "@/components/AsideMenu";

export default {
  name: "HomePage",
  components: {
    AsideMenu: AsideMenu,
  },
  computed: {
    uid() {
      return localStorage.getItem('uid')
    },
    token() {
      return localStorage.getItem('token')
    }
  },
  methods: {
    download() {
      this.$router.push('/app')
    },
    logOut() {
      this.$http({
        url: this.$http.adornUrl('user/logout'),
        method: 'post',
        data: this.$http.adornData({})
      }).then(({data}) => {
        console.log(data.msg)
      })
      this.$router.push('/')
    },
  },
  mounted() {
    // document.title = '爱勇者（医生和管理端）'
    document.title = '昆仑智鼎'
  }
}
</script>

<style scoped>
html, body, #app {
  height: 100%;
  margin: 0;
}

.flex-container {
  display: flex;
  align-items: center; /* 垂直居中对齐子元素 */
  justify-content: space-between; /* 子元素在水平方向上等距分布，首尾贴边 */
  border-bottom: solid #DCDFE6;
  grid-template-columns: 9fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: #545c64;
  color: #fff;
}

.flex-item {
  /* 根据需要设置样式 */
}

.flex-item-group {
  display: flex;
  align-items: center; /* 垂直居中对齐内部的el-link */
}

/* 如果你想要el-link之间有间距，可以添加margin */
.flex-item-group .flex-item:not(:last-child) {
  margin-right: 60px; /* 根据需要设置间距 */
  margin: 20px
}

</style>