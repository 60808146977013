const baseUrl = 'https://www.tripot.cn/braintumor-api/'
const urlPrefix = '/braintumor-api/'
// const baseUrl = 'https://localhost:8081/braintumor-api/'
// const urlPrefix = 'http:ss//localhost:8081/braintumor-api/'

// 基础用户url
const baseUserUrl = urlPrefix + 'user/'
const getMyMsg = baseUserUrl + 'getMyMsg'
const updateMsg = baseUserUrl + 'updateMsg'

const baseFirmwareUrl = urlPrefix + 'firmware/'
const getNewAppDownloadInfo = baseFirmwareUrl + 'getNewAppDownloadInfo'


export default {
  urlPrefix,
  
  baseUrl,
  getMyMsg,
  updateMsg,

  getNewAppDownloadInfo


}

