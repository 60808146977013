<template>
  <div>
    <el-card>
      <el-form inline>
        <el-form-item label="商品状态: ">
          <el-select v-model="goodsState" placeholder="请选择商品状态" @change="goodsStateChangeHandle">
            <el-option
              v-for="type in goodsStateType"
              :key="type.value"
              :value="type.value"
              :label="type.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button @click="handleAddGoods" type="success">添加商品</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 30px">
      <!-- 商品列表 -->
      <el-table :data="goods" border style="margin-top: 15px">
        <el-table-column label="商品id" prop="id" align="center" width="100px" />
        <el-table-column label="商品名称" prop="title" align="center" width="280px" />
        <el-table-column label="商品描述" prop="introduce" align="center" />
        <el-table-column label="商品价格" prop="price" align="center" width="120px" />
        <el-table-column label="商品状态" prop="goodsState" align="center" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.goodsState === 1" type="success" size="medium" effect="dark">已上架</el-tag>
            <el-tag v-else size="medium" type="primary" effect="dark">未上架</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="咨询电话" prop="phone" align="center" width="180px" />
        <el-table-column label="上传时间" prop="uploadTime" align="center" width="200px" :formatter="exchangeTime" />
        <el-table-column label="操作" align="center" width="400px">
          <template slot-scope="scope">
            <el-button @click="editGoods(scope.row)">编辑商品</el-button>
            <el-button v-if="scope.row.goodsState === 0" type="success" @click="approve(scope.row)">上架商品</el-button>
            <el-button v-if="scope.row.goodsState === 1" type="primary" @click="reject(scope.row)">下架商品</el-button>
            <el-button @click="deleteGoods(scope.row)" type="danger">删除商品</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        class="pagination"
        align="right"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
      />
    </el-card>
    <el-dialog
      :title="updateGoods.id == 0 ? '新增商品' : '编辑商品'"
      :visible.sync="dialogVisible" 
      :before-close="beforeClose">
      <el-form ref="dataForm" :model="updateGoods" :rules="dataRule" label-width="120px" @keyup.enter.native="dataFormSubmit()">
        <el-form-item label="商品名称" prop="title">
          <el-input v-model="updateGoods.title" placeholder="商品名称" maxlength="20" />
        </el-form-item>
        <el-form-item label="商品描述" prop="introduce">
          <el-input v-model="updateGoods.introduce" placeholder="商品描述" maxlength="40" />
        </el-form-item>
        <el-form-item label="商品图片" prop="topPictures">
          <images-upload
            :url="updateGoods.topPictures"
            namePrefix="top_"
            :policyUrl="signatureUrl"
            name="image"
            @on-success="uploadGoodsTopSuccess"
          />
        </el-form-item>
        <el-form-item label="商品描述图片" prop="contentPictures">
          <images-upload
            :url="updateGoods.contentPictures"
            namePrefix="content_"
            :policyUrl="signatureUrl"
            name="image"
            @on-success="uploadGoodsContentSuccess"
          />
        </el-form-item>
        <el-form-item label="商品价格" prop="price">
          <el-input-number
            v-model="updateGoods.price"
            :precision="2"
            size="small"
          />
          <!-- <el-input v-model="updateGoods.price" placeholder="商品价格" maxlength="10" /> -->
        </el-form-item>
        <el-form-item label="咨询电话" prop="phone">
          <el-input v-model="updateGoods.phone" placeholder="咨询电话" maxlength="16" />
        </el-form-item>
      </el-form>    
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      </span> 
    </el-dialog>
  </div>
</template>

<script>
import ImagesUpload from "@/components/ImagesUpload";
export default {
  name: "NewsManage",
  components: {
    ImagesUpload: ImagesUpload,
  },
  data() {
    return {
      goodsState: -1,
      goodsStateType: [{
        value: -1,
        label: "全部"
      }, {
        value: 0,
        label: "未上架商品"
      }, {
        value: 1,
        label: "已上架商品"
      }],
      goods: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 1,
      dialogVisible: false,
      updateGoods: {},
      signatureUrl: 'goods/getUploadSignature',
      dataRule: {
        title: [
          {required: true, message: '商品名称不能为空', trigger: blur}
        ],
        introduce: [
          {required: true, message: '商品介绍不能为空', trigger: blur}
        ],
        topPictures: [
          {required: true, message: '商品图片不能为空', trigger: blur}
        ],
        contentPictures: [
          {required: true, message: '商品描述图片不能为空', trigger: blur}
        ],
        price: [
          {required: true, message: '价格不能为空', trigger: blur}
        ]
      }
    }
  },
  methods: {
    editGoods(row) {
      this.updateGoods = row
      this.dialogVisible = true
    },
    handleAddGoods() {
      this.updateGoods = {
        id: 0,
        title: '',
        introduce: '',
        topPictures: [],
        contentPictures: [],
        price: 0.0,
        phone: ''
      }
      this.dialogVisible = true
    },
    // 转换开始领取时间
    exchangeTime(row) {
      return this.$timeStampToString(row.uploadTime)
    },
    goodsStateChangeHandle() {
      this.pageIndex = 1
      this.getGoods()
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getGoods()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getGoods()
    },
    deleteGoods(row) {
      this.$confirm('是否删除商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'danger'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('goods/deleteGoods'),
          method: 'post',
          data: {
            id: row.id
          }          
        }).then(() => {
          this.pageIndex = 1
          this.getGoods()
          this.$message.success("删除成功")
        }).error(err => {
          this.$message.error(err)
        });
      })
    },
    getGoods() {
      this.$http({
        url: this.$http.adornUrl('goods/queryGoods'),
        method: 'post',
        data: {
          page: this.pageIndex,
          limit: this.pageSize,
          goodsState: this.goodsState
        }
      }).then(data => {
        this.goods = data.data.list
        this.totalPage = data.data.totalCount
      })
    },
    approve(row) {
      this.$confirm('是否上架商品', '提示', {
        confirmButtonText: '上架商品',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateGoodsState(row.id, 1)
      })
    },
    reject(row) {
      this.$confirm('是否下架商品？', '提示', {
        confirmButtonText: '下架商品',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateGoodsState(row.id, 0)
      })
    },
    uploadGoodsTopSuccess(res) {
      if (res.code == 0) {
        this.$message({
          message: '上传成功',
          type: 'success'
        })
        this.updateGoods.topPictures.push(res.imageUrl)
      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    uploadGoodsContentSuccess(res) {
      if (res.code == 0) {
        this.$message({
          message: '上传成功',
          type: 'success'
        })
        this.updateGoods.contentPictures.push(res.imageUrl)
      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if(valid) {
          this.$http({
            url: this.$http.adornUrl('goods/add-or-update'),
            method: 'post',
            data: this.updateGoods
          }).then(() => {
            this.$message.success(this.updateGoods.id == 0 ? '新增成功' : '更新成功')
            this.dialogVisible = false
            this.getGoods()
          }).catch(err => {
            this.$message.error(err)
          })
        }
      })
    },
    updateGoodsState(id, goodsState) {
      this.$http({
        url: this.$http.adornUrl('goods/updateGoodsState'),
        method: 'post',
        data: {
          id: id,
          goodsState: goodsState
        }          
      }).then(() => {
        this.getGoods()
        this.$message.success(goodsState == 0 ? "商品下架成功" : "商品上架成功")
      }).error(err => {
        this.$message.error(err)
      })
    }
  },
  mounted() {
    this.getGoods()
  }
}
</script>

<style scoped>
.pagination {
  padding: 50px 35px 30px;
}
</style>