<template>
  <el-card>
    <div slot="header">
      <span>上传病历</span>
      <el-button style="float: right; padding: 3px 0" type="text" @click="upload">提交</el-button>
    </div>
    <el-form :model="form">
      <el-form-item label="请输入患者名：">
        <el-select v-model="form.patientId" filterable placeholder="请选择">
          <el-option
            v-for="item in users"
            :key="item.key"
            :label="item.value"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="请输入内容：">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="form.briefIntroduction">
        </el-input>
      </el-form-item>

      <el-form-item label="上传病例图片：">
        <!-- <el-upload
          action="https://braintumor.oss-cn-hangzhou.aliyuncs.com/avatar/"
          :file-list="form.fileList">
          <el-button type="primary">点击上传</el-button>
        </el-upload> -->
        <!-- <el-upload
          :file-list="form.image"
          :on-change="handleChange"
          action="/medicalRecord/uploadMedicalRecord"
          list-type="picture"
          :auto-upload="false"
          multiple>
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload> -->
        <el-upload
          ref="upload"            
          action=""
          :http-request="httpRequest"
          :on-remove="handleRemove"
          :before-upload="handleBeforeUpload"
          :on-exceed="handleExceed"
          :limit="7"
          :file-list="files"
          list-type="picture" 
          multiple>
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>    
  </el-card>

</template>

<script>
import axios from "axios";

export default {
  name: "MedicalRecord",
  data() {
    return {
      form: {
        patientId: '',
        briefIntroduction: '',
        image: []
      },

      users: [],
      policy: {},
      files: [],
      offset: 0
    }
  },
  methods: {
    getUsers() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/user/getRelatePatient',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.users = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    upload() {
      // var formData = new FormData()
      // formData.append('patientUid', this.form.patientUid)
      // formData.append('briefIntroduction', this.form.briefIntroduction)
      // this.form.image.forEach(item => formData.append('image', item.raw))

      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/medicalRecord/managerUploadMedicalRecord',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          patientId: this.form.patientId,
          briefIntroduction: this.form.briefIntroduction,
          image: this.form.image.map(img => img.url)
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.$message.success('上传成功')
          this.form = {}
          this.files = []
          this.offset = 0
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    handleChange(file, fileList) {
      // 文件状态改变时的钩子，可以用来更新form.files
      this.form.image = fileList;
    },

    getSignature(){
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/medicalRecord/getUploadSignature',
        method: 'get',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.policy = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    httpRequest(file) {
      let time = new Date().getTime().toString().slice(0, 10)
      if (Number.parseInt(time) > Number.parseInt(this.policy.expire)) {
        this.getSignature()
        this.$message.error('身份信息失败,请重新上传')
      } else {
        var formData = new FormData()
        const fileName = file.file.name.split('.')
        let imageUrlName = this.policy.dir + this.offset + '_' + Number.parseInt(time) + '.' + fileName[fileName.length - 1]
        // 注意formData里append添加的键的大小写
        formData.append('key', imageUrlName) // 存储在oss的文件路径
        formData.append('OSSAccessKeyId', this.policy.accessId) // accessKeyId
        formData.append('policy', this.policy.policy)   // policy
        formData.append('Signature', this.policy.signature) // 签名
        formData.append('file', file.file)
        formData.append('success_action_status', 200) // 成功后返回的操作码

        axios({
          url: this.policy.host,
          method: 'POST',
          data: formData
        }).then(data1 => {
          if (!data1) {
            this.$message.error('上传失败')
            return
          }
          if (data1.status && data1.status == 200) {
            this.form.image.push({id: file.file.uid, url: this.policy.host + '/' + imageUrlName})
            this.offset = this.offset + 1
          } else {
            this.$message.error(data1)
          }
        }).catch(err => {
          this.$message.error(err)
        })
      }
    },

    // 上传文件
    handleBeforeUpload() {
      return true
    },
    handleSuccess() {
      this.$message.success('上传成功')
    },
    handleRemove(file) {
      this.form.image = this.form.image.filter(img => img.id !== file.uid)
    },
    handleExceed() {
      this.$message.error(`最多只能上传 7 个文件`);
    }
  },
  mounted() {
    this.getUsers()
    this.getSignature()
  }
}
</script>

<style scoped>

</style>