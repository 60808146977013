<template>
  <el-card>
    <el-table :data="newsList" border>
      <el-table-column label="资讯标题" prop="title" align="center" width="180px"></el-table-column>
      <el-table-column label="资讯缩略图" align="center" width="120px">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.pictureUrl"
            style="height: 90px"
            fit="contain"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="资讯链接">
        <template slot-scope="scope">
          <a :href="scope.row.webUrl">{{ scope.row.webUrl }}</a>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="180px" prop="newsState"></el-table-column>
      <el-table-column label="删除" align="center" width="180px">
        <template slot-scope="scope">
          <el-button type="text" @click="deleteNews(scope.row)" style="color: red">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import axios from 'axios'
export default {
  name: "MyNews",
  data() {
    return {
      newsList: [],
      state: ['待审核', '正常', '已删除', '审核不通过']
    }
  },
  methods: {
    getMyNews() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/news/getMyNews',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.newsList = []
          response.data.data.map(news => {
            if(news.newsState < 2) {
              this.newsList.push({
                id: news.id,
                title: news.title,
                pictureUrl: news.pictureUrl,
                webUrl: news.webUrl,
                newsState: this.state[news.newsState]
              })
            }
          })
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    deleteNews(row) {
      let time = new Date().getTime().toString().slice(0, 10)

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url: '/news/updateNewsState',
          method: 'post',
          headers: {
            "uid": localStorage.getItem('uid').toString(),
            "token": this.$md5(time + localStorage.getItem('token')),
            "time": time,
            "type": localStorage.getItem('userType').toString()
          },
          data: {
            id: row.id,
            newsState: 2
          }
        }).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: "warning",
              message: '请求删除成功'
            })
            this.getMyNews()
          }
        })
      })
    }
  },
  mounted() {
    this.getMyNews()
  }
}
</script>

<style scoped>

</style>