<template>
  <div>
    <el-card>
      标题：<el-input style="width: 400px; margin-bottom: 20px;" placheholder="请输入标题" v-model="title">
        <el-button @click="commitEditor" slot="append">发布</el-button>
      </el-input>      
    </el-card>

    <el-card style="margin-top: 10px;">
      <div id="editor"></div>      
    </el-card>
  </div>
</template>

<script>
import E from 'wangeditor'
import axios from "axios"

export default {
  name: "TextEdit",
  data() {
    return {
      editor: null,
      editorData: '',
      title: '',
      policy: {}
    }
  },
  computed: {
    header() {
      let time = new Date().getTime().toString().slice(0, 10)
      return {
        "uid": localStorage.getItem('uid').toString(),
        "token": this.$md5(time + localStorage.getItem('token')),
        "time": time,
        "type": localStorage.getItem('userType').toString()
      }
    }
  },
  methods: {
    getHeaders(time) {
      return {
        "uid": localStorage.getItem('uid').toString(),
        "token": this.$md5(time + localStorage.getItem('token')),
        "time": time,
        "type": localStorage.getItem('userType').toString()
      }
    },

    getSignature(){
      let time = new Date().getTime().toString().slice(0, 10)
      // this.$http({
      //   url: this.$http.adornUrl('news/getUploadSignature'),
      //   method: 'get',
      //   params: this.$http.adornParams({
      //     'page': this.pageIndex,
      //     'limit': this.pageSize
      //   })
      // }).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.totalPage = data.page.totalPage
      //     this.pbList.push(...data.page.list)
      //   } else {
      //     this.dataList = []
      //     this.totalPage = 0
      //   }
      //   this.dataListLoading = false
      // })
      axios({
        url: '/news/getUploadSignature',
        method: 'get',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.policy = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    editorConfig(editor) {
      // 设置编辑区域高度为 500px
      editor.config.height = 1000
      // 配置默认提示
      editor.config.placeholder = '请输入想要发布的内容'
      // 开启全屏
      editor.config.showFullScreen = true
      // 图标提示向下
      editor.config.menuTooltipPosition = 'down'
      // editor.config.zIndex = 500

      // 配置图片上传
      // editor.config.uploadImgShowBase64 = true
      // editor.config.uploadImgServer = 'https://www.tripot.cn/braintumor-api/news/web/uploadImage'
      // editor.config.uploadImgHeaders = this.header
      // editor.config.uploadFileName = 'image'
      // editor.config.showLinkImg = false
      // editor.config.uploadImgHooks = {
      //   success: res => {
      //     this.pictureUrl = JSON.parse(res.response).data[0].url
      //     console.log(this.pictureUrl)
      //     console.log(JSON.parse(res.response))
      //   }
      // }
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        resultFiles.map(file => {
          let time = new Date().getTime().toString().slice(0, 10)
          axios({
            url: '/news/getUploadSignature',
            method: 'get',
            headers: {
              "uid": localStorage.getItem('uid').toString(),
              "token": this.$md5(time + localStorage.getItem('token')),
              "time": time,
              "type": localStorage.getItem('userType').toString()
            }
          }).then(response => {
            if(response.data.code === 200) {
              let policy = response.data.data
              var formData = new FormData()
              let imageUrlName = policy.dir + Number.parseInt(time) + file.name
              // 注意formData里append添加的键的大小写
              formData.append('key', imageUrlName) // 存储在oss的文件路径
              formData.append('OSSAccessKeyId', policy.accessId) // accessKeyId
              formData.append('policy', policy.policy)   // policy
              formData.append('Signature', policy.signature) // 签名
              formData.append('file', file)
              formData.append('success_action_status', 200) // 成功后返回的操作码
              axios({
                url: policy.host,
                method: 'POST',
                data: formData,
                timeout: 300000
              }).then(data1 => {
                if (!data1) {
                  this.$message.error('上传失败')
                  return
                }
                if (data1.status && data1.status == 200) {
                  console.log(policy.host + imageUrlName);
                  insertImgFn(policy.host + '/' + imageUrlName)
                } else {
                  this.$message.error(data1)
                }
              }).catch(err => {
                this.$message.error(err)
              })
            }
          }).catch(err => {
            console.log(err)
          })
        })
      }

      // 粘贴图片
      editor.config.pasteIgnoreImg = true
      // 上传视频
      editor.config.showLinkVideo = false
    },

    commitEditor() {
      this.editorData = this.editor.txt.html()
      console.log(this.editorData)
      axios({
        url: 'news/web/sendNews',
        method: 'post',
        headers: this.header,
        data: {
          picture: this.pictureUrl,
          richText: this.editorData,
          contentTitle: this.title
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.$message.success('发布成功')
          this.title = ''
          this.editorData = ''
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    httpRequest(file) {
      console.log(file);
      let time = new Date().getTime().toString().slice(0, 10)
      if (Number.parseInt(time) > Number.parseInt(this.policy.expire)) {
        this.getSignature()
        this.$message.error('身份信息失败,请重新上传')
      } else {
        var formData = new FormData()
        let imageUrlName = this.policy.dir + Number.parseInt(time) + file.name
        // 注意formData里append添加的键的大小写
        formData.append('key', imageUrlName) // 存储在oss的文件路径
        formData.append('OSSAccessKeyId', this.policy.accessId) // accessKeyId
        formData.append('policy', this.policy.policy)   // policy
        formData.append('Signature', this.policy.signature) // 签名
        formData.append('file', file)
        formData.append('success_action_status', 200) // 成功后返回的操作码

        axios({
          url: this.policy.host,
          method: 'POST',
          data: formData
        }).then(data1 => {
          if (!data1) {
            this.$message.error('上传失败')
            return
          }
          if (data1.status && data1.status == 200) {
            return imageUrlName
          } else {
            this.$message.error(data1)
          }
        }).catch(err => {
          this.$message.error(err)
        })
      }
    }
  },
  mounted() {
    this.getSignature()

    const editor = new E('#editor')
    // 配置 editor
    this.editorConfig(editor)
    // 创建编辑器
    editor.create()
    this.editor = editor
  },
  beforeDestroy() {
    // 销毁 editor
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style scoped>


</style>