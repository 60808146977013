<template>
  <div class="upload-container">
    <div v-for="(item, index) in url" :key="index" class="images">
      <el-image v-if="index < max" :src="item" :style="`width:${width}px;height:${height}px;`" fit="cover" />
      <div class="mask">
        <div class="actions">
          <span title="预览" @click="preview(index)">
            <i class="el-icon-zoom-in" />
          </span>
          <span title="移除" @click="remove(index)">
            <i class="el-icon-delete" />
          </span>
          <span v-show="url.length > 1" title="左移" :class="{'disabled': index == 0}" @click="move(index, 'left')">
            <i class="el-icon-back" />
          </span>
          <span v-show="url.length > 1" title="右移" :class="{'disabled': index == url.length - 1}" @click="move(index, 'right')">
            <i class="el-icon-right" />
          </span>
        </div>
      </div>
    </div>
    <el-upload
      v-show="url.length < max"
      :show-file-list="false"
      action=""
      :http-request="httpRequest"
      :disabled="disabled"
      :data="data"
      :name="name"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
      drag
      class="images-upload"
    >
      <div class="image-slot" :style="`width:${width}px;height:${height}px;`">
        <i class="el-icon-plus" />
      </div>
      <div v-show="progress.percent" class="progress" :style="`width:${width}px;height:${height}px;`">
        <el-image :src="progress.preview" :style="`width:${width}px;height:${height}px;`" fit="fill" />
        <el-progress type="circle" :width="Math.min(width, height) * 0.8" :percentage="progress.percent" />
      </div>
    </el-upload>
    <div v-if="!notip" class="el-upload__tip">
      <div style="display: inline-block;">
        <el-alert :title="`上传图片支持 ${ ext.join(' / ') } 格式，单张图片大小不超过 ${ size }MB，且图片数量不超过 ${ max } 张`" type="info" show-icon :closable="false" />
      </div>
    </div>
    <el-image-viewer v-if="imageViewerVisible" :on-close="() => {imageViewerVisible = false}" :url-list="[dialogImageUrl]" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import axios from "axios";

export default {
  name: 'ImagesUpload',
  components: {
    ElImageViewer
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: 'file'
    },
    namePrefix: {
      type: String,
      default: ''
    },
    url: {
      type: Array,
      default: () => []
    },
    policyUrl: {
      type: String,
      default: () => ''
    },
    max: {
      type: Number,
      default: 7
    },
    size: {
      type: Number,
      default: 5
    },
    width: {
      type: Number,
      default: 150
    },
    height: {
      type: Number,
      default: 150
    },
    placeholder: {
      type: String,
      default: ''
    },
    notip: {
      type: Boolean,
      default: false
    },
    ext: {
      type: Array,
      default: () => ['jpg', 'png']
    }
  },
  data() {
    return {
      policy: {},
      dialogImageUrl: '',
      imageViewerVisible: false,
      progress: {
        preview: '',
        percent: 0
      }
    }
  },
  mounted() {
    this.updatePolicy()
  },
  methods: {

    // 预览
    preview(index) {
      this.dialogImageUrl = this.url[index]
      this.imageViewerVisible = true
    },
    // 移除
    remove(index) {
      if (this.disabled) {
        this.$message.error('请先打开编辑')
        return
      }
      let url = this.url
      url.splice(index, 1)
      this.$emit('update:url', url)
    },
    // 移动
    move(index, type) {
      let url = this.url
      if (type == 'left' && index != 0) {
        url[index] = url.splice(index - 1, 1, url[index])[0]
      }
      if (type == 'right' && index != url.length - 1) {
        url[index] = url.splice(index + 1, 1, url[index])[0]
      }
      this.$emit('update:url', url)
    },
    beforeUpload(file) {
      const fileName = file.name.split('.')
      const fileExt = fileName[fileName.length - 1]
      const isTypeOk = this.ext.indexOf(fileExt) >= 0
      const isSizeOk = file.size / 1024 / 1024 < this.size
      if (!isTypeOk) {
        this.$message.error(`上传图片只支持 ${ this.ext.join(' / ') } 格式！`)
      }
      if (!isSizeOk) {
        this.$message.error(`上传图片大小不能超过 ${this.size}MB！`)
      }
      if (isTypeOk && isSizeOk) {
        this.progress.preview = URL.createObjectURL(file)
      }
      let isSignatureOk = true
      if (!this.policy.signature) {
        isSignatureOk = false
        this.$message.error('请求还未初始化')
      }
      return isTypeOk && isSizeOk && isSignatureOk
    },

    updatePolicy() {
      this.$http({
        url: this.$http.adornUrl(this.policyUrl),
        method: 'GET',
        data: {}
      }).then(({data}) => {
        this.policy = data
      }).catch(err => {
        this.$message.error(err)
      })
    },

    httpRequest(data) {
      let _this = this
      var time = Date.parse(new Date()) / 1000
      if (Number.parseInt(time) > Number.parseInt(this.policy.expire)) {
        this.updatePolicy()
        this.$message.error('身份信息失败,请重新上传')
      } else {
        var formData = new FormData()
        let imageUrlName = this.policy.dir + this.namePrefix + data.file.name
        // 注意formData里append添加的键的大小写
        formData.append('key', imageUrlName) // 存储在oss的文件路径
        formData.append('OSSAccessKeyId', this.policy.accessId) // accessKeyId
        formData.append('policy', this.policy.policy)   // policy
        formData.append('Signature', this.policy.signature) // 签名
        formData.append('file', data.file)
        formData.append('success_action_status', 200) // 成功后返回的操作码
        axios({
          url: this.policy.host,
          method: 'POST',
          data: formData,
          timeout: 300000
        }).then(data1 => {
          if (!data1) {
            this.$message.error('上传失败')
            return
          }
          if (data1.status && data1.status == 200) {
            _this.onSuccess({
              code: 0,
              imageUrl: _this.policy.host + '/' + imageUrlName
            })
          } else {
            this.$message.error(data1)
          }
        }).catch(err => {
          this.$message.error(err)
        })
      }
    },
    onProgress(file) {
      this.progress.percent = ~~file.percent
      if (this.progress.percent == 100) {
        this.progress.preview = ''
        this.progress.percent = 0
      }
    },
    onSuccess(res) {
      this.$emit('on-success', res)
    }
  }
}
</script>

<style scoped>
.upload-container {  
  line-height: initial;  
}  
  
.images {  
  position: relative;  
  display: inline-block;  
  margin-right: 10px;  
  border: 1px dashed #d9d9d9;  
  border-radius: 6px;  
  overflow: hidden;  
}  
  
.images .el-image {  
  display: block;  
}  
  
.images .mask {  
  opacity: 0;  
  position: absolute;  
  top: 0;  
  width: 100%;  
  height: 100%;  
  font-size: 24px;  
  background-color: rgba(0, 0, 0, 0.918);  
  transition: all 0.3s;  
}  
  
.images .mask .actions {  
  width: 100px;  
  height: 100px;  
  display: flex;  
  flex-wrap: wrap;  
  align-items: center;  
  justify-content: center;  
  position: absolute;  
  top: 50%;  
  left: 50%;  
  transform: translate(-50%, -50%);  
}  
  
.images .mask .actions span {  
  width: 50%;  
  text-align: center;  
  color: #fff;  
  cursor: pointer;  
  transition: all 0.1s;  
}  
  
.images .mask .actions span.disabled {  
  color: #999;  
  cursor: not-allowed;  
}  
  
.images .mask .actions span:hover:not(.disabled) {  
  transform: scale(1.5);  
}  
  
.images:hover .mask {  
  opacity: 1;  
}  
  
.images-upload {  
  display: inline-block;  
}  
  
/* 注意：CSS中没有直接的::v-deep选择器，这通常用于Vue组件样式穿透。  
   在CSS中，你需要在Vue组件中直接使用类名或ID来覆盖子组件的样式。 */  
  
.el-upload .el-upload-dragger {  
  width: auto;  
  height: auto;  
}  
  
.el-upload .el-upload-dragger.is-dragover {  
  border-width: 1px;  
}  
  
.el-upload .el-upload-dragger .image-slot {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  width: 100%;  
  height: 100%;  
  color: #909399;  
  font-size: 30px;  
  background-color: transparent;  
}  
  
.el-upload .el-upload-dragger .progress {  
  position: absolute;  
  top: 0;  
}  
  
.el-upload .el-upload-dragger .progress::after {  
  content: '';  
  position: absolute;  
  width: 100%;  
  height: 100%;  
  left: 0;  
  top: 0;  
  background-color: rgba(0, 0, 0, 0.2);  
}  
  
.el-upload .el-upload-dragger .progress .el-progress {  
  z-index: 1;  
  position: absolute;  
  top: 50%;  
  left: 50%;  
  transform: translate(-50%, -50%);  
}  
  
.el-upload .el-upload-dragger .progress .el-progress .el-progress__text {  
  color: #fff;  
}
</style>
