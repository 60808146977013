<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="12">
          <div id="userchart" style="width:320px; height:320px;"></div>
        </el-col>
        <el-col :span="12">
          <div id="devicechart" style="width:320px; height:320px;"></div> 
        </el-col>
      </el-row>
    </el-card>

    <!-- 表格筛选和图表展示 -->
    <div>
      <!-- 筛选表单 -->
      <el-card style="margin-top: 10px;">
        <el-form id="form">
          <el-form-item label="癌症类型：">
            <el-select
              v-model="searchValue.patientValue"
              placeholder="请选择病人类型"
              clearable
              @change="searchByTumorType">
              <el-option
                v-for="option in patientOptions"
                :key="option.value"
                :value="option.value"
                :label="option.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择区域：">
            <el-cascader
              v-model="searchValue.areaValue"
              :options="areaOptions"
              :props="{ checkStrictly: true}"
              @change="searchByAreaValue"
              clearable
              filterable>
            </el-cascader>
          </el-form-item>
        </el-form>            
      </el-card>
    </div>

    <el-card style="margin-top: 10px;">
      <div id="table">
        <!-- 数据 -->
        <!--v-bind:data="patients.slice((currentPage-1)*pageSize, currentPage*pageSize)"-->
        <el-table
          :data="patients"
          border
          @row-click="showPInfo">
          <el-table-column prop="uid" label="患者id" width="120"></el-table-column>
          <el-table-column prop="username" label="患者姓名" width="120"></el-table-column>
          <el-table-column prop="gender" label="患者性别" width="80"></el-table-column>
          <el-table-column label="住址" width="320">
            <template slot-scope="scope">
              {{scope.row.addressProvince + " " + scope.row.addressCity + " " + scope.row.addressDistrict + " " + scope.row.addressDetail}}
            </template>
          </el-table-column>
          <el-table-column prop="hospital" label="医院" width="80"></el-table-column>
          <el-table-column label="紧急联系人" width="320">
            <template slot-scope="scope">
              {{scope.row.getEmergencyContactName + "，" + scope.row.emergencyContactPhone}}
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          layout="total, prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-count="totalPages">
        </el-pagination>
      </div>      
    </el-card>
    <!-- 表格 -->


    <!-- 右侧抽屉，点击患者从右侧弹窗展示患者详细信息 -->
    <el-drawer title="患者信息" :visible.sync="drawerVisible">
      <el-form id="drawerBody">
        <el-form-item label="姓名">
          <span>{{patient.username}}</span>
        </el-form-item>
        <el-form-item label="生日">
          <span>{{patient.birthday}}</span>
        </el-form-item>
        <el-form-item label="性别">
          <span>{{patient.gender}}</span>
        </el-form-item>
        <el-form-item label="所在地区">
          <span>{{patient.addressProvince}}</span>
        </el-form-item>
        <el-form-item label="肿瘤类型">
          <span>{{patient.tumorType}}</span>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";

import {mapState, mapMutations} from "vuex";
import * as echarts from 'echarts';
import pcaDate from '../statistic/pca-code.json'

export default {
  name: "PatientList",
  data(){
    return{
      userData: this.$store.state.loginUser.userData,

      currentPage:1,
      pageSize:10,

      patientOptions:[
        {value:'脑瘤', label:'脑瘤'},
        {value:'肝癌', label:'肝癌'},
      ],
      deviceOptions:[
        {value: '脑瘤设备', label: '脑瘤设备'},
        {value: '手环设备', label: '手环设备'}
      ],
      areaOptions: pcaDate,

      searchValue: {
        patientValue: '',
        deviceValue: '',
        areaValue: '',
      },

      drawerVisible: false,

      deviceData: []
    }
  },
  computed: {
    ...mapState('patientsTable', ['patients', 'totalPages', 'patient']),
  },
  methods: {
    ...mapMutations('patientsTable', ['SHOW_PATIENTS', 'NEXT_PAGE', 'REVERSE_CURRENTPAGE', 'GET_PATIENT_BY_ID']),

    // 处理换行
    handleSizeChange(size) {
      this.pageSize = size;
      console.log(size);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.NEXT_PAGE(currentPage - 1)
      this.SHOW_PATIENTS(this.searchValue)
    },

    // 图表设置函数
    showChart() {
      // 图表初始化
      const userchart = echarts.init(document.getElementById('userchart'));
      const devicechart = echarts.init(document.getElementById('devicechart'));
      // 图表设置
      userchart.setOption({
        title: {
          text: '患者统计'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            data: this.userData.map(item => {
              return {
                value: item.num,
                name: item.name
              }
            }),
            radius: '50%'
          }
        ]
      })
      devicechart.setOption({
        title: {
          text: '设备统计'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            data: this.deviceData.map(item => {
              return {
                name: item.name,
                value: item.num
              }
            }),
            radius: '50%'
          }
        ]
      })

      console.log(userchart);
      // 响应图表容器大小并改变图表大小
      window.onresize = function () {
        userchart.resize()
        devicechart.resize()
      };
    },

    // 查询信息
    searchByTumorType() {
      this.REVERSE_CURRENTPAGE()
      this.SHOW_PATIENTS(this.searchValue)
    },
    searchByDeviceType() {
      this.REVERSE_CURRENTPAGE()
      this.SHOW_PATIENTS(this.searchValue)
    },
    searchByAreaValue() {
      this.REVERSE_CURRENTPAGE()
      this.SHOW_PATIENTS(this.searchValue)
    },

    // 点击某行展示患者详细信息
    showPInfo(row) {
      console.log(row)
      this.GET_PATIENT_BY_ID(row.uid)
      this.drawerVisible = true
    },

    getStatic() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: 'user/getStatisticalData',
        method: 'get',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.userData = response.data.data.userStatisticalData
          this.deviceData = response.data.data.deviceStatisticalData
          this.showChart()
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.getStatic()
    this.SHOW_PATIENTS(this.searchValue)
  }
}
</script>

<style scoped>
#form {
  padding: 10px;
  width: 40%;
  float: left;
}
#table {
  margin: 30px;
}
#drawerBody {
  margin-left: 20px;
}
</style>