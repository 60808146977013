<template>
  <div>
    <el-card>
      <el-row type="flex">
        <el-col :span="8">
          <el-form label-width="180px">
            <el-form-item label="请选择设备类型：">
              <el-select v-model="selectTypeValue" @change="getFirmWareListByType" clearable>
                <el-option
                  v-for="option in deviceOptions"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" :offset="12">
          <el-button type="primary" @click="dialogVisible = true">发布新的固件</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 30px">
      <!-- 固件列表 -->
      <el-table border style="margin-top: 15px" :data="firmWareList">
        <el-table-column label="设备类型" width="120px" align="center" prop="deviceType"></el-table-column>
        <el-table-column label="版本" width="120px" align="center" prop="version"></el-table-column>
        <el-table-column label="详情" prop="briefIntroduce"></el-table-column>
        <el-table-column label="下载链接" prop="url"></el-table-column>
      </el-table>
    </el-card>
    <!-- 发布固件 -->
    <el-dialog title="发布固件" :visible.sync="dialogVisible" width="30%">
      <el-form :model="firmWareForm" label-position="top">
        <el-form-item label="设备类型：">
          <el-select v-model="firmWareForm.type">
            <el-option
              v-for="option in deviceOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本：">
          <el-input v-model="firmWareForm.version" placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="详情：">
          <el-input v-model="firmWareForm.introduce"
                    :autosize="{ minRows: 5}"
                    placeholder="请输入固件更新内容"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="文件：">
          <el-upload
            ref="upload"            
            action=""
            :http-request="httpRequest"
            :on-remove="handleRemove"
            :before-upload="handleBeforeUpload"
            :on-exceed="handleExceed"
            :limit="1"
            :file-list="files">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "FirmWareManage",
  data() {
    return {
      dialogVisible: false,
      firmWareForm: {
        version: '',
        type: '',
        introduce: '',
        url: ''
      },
      selectTypeValue: '',
      deviceOptions:[
        {value: '脑瘤软件用户端', label: '脑瘤软件用户端'},
        {value: '脑瘤软件企业端', label: '脑瘤软件企业端'},
      ],
      firmWareList: [],

      policy: {},
      files: []
    }
  },
  computed: {
    firmWareUploadUrl() {
      return 'https://www.tripot.cn/braintumor-api/firmware/uploadFirmware'
    },
    header() {
      let time = new Date().getTime().toString().slice(0, 10)
      return {
        "uid": localStorage.getItem('uid').toString(),
        "token": this.$md5(time + localStorage.getItem('token')),
        "time": time,
        "type": localStorage.getItem('userType').toString()
      }
    }
  },
  methods: {
    getFirmWareList() {
      axios({
        url: '/firmware/getAllFirmware',
        method: 'post',
        headers: this.header
      }).then(res => {
        if(res.data.code === 200) {
          this.firmWareList = res.data.data
        }
      })
    },

    getFirmWareListByType() {
      if(this.selectTypeValue !== '') {
        axios({
          url: '/firmware/getFirmwareByDeviceType',
          method: 'post',
          headers: this.header,
          data: {
            deviceType: this.selectTypeValue
          }
        }).then(res => {
          if(res.data.code === 200) {
            this.firmWareList = res.data.data
          }
        })
      } else {
        this.getFirmWareList()
      }
    },

    getSignature(){
      axios({
        url: '/firmware/getUploadSignature',
        method: 'get',
        headers: this.header
      }).then(async response => {
        if(response.data.code === 200) {
          this.policy = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    httpRequest(file) {
      let time = new Date().getTime().toString().slice(0, 10)
      if (Number.parseInt(time) > Number.parseInt(this.policy.expire)) {
        this.getSignature()
        this.$message.error('身份信息失败,请重新上传')
      } else {
        var formData = new FormData()
        let url = this.policy.dir + file.file.name
        // 注意formData里append添加的键的大小写
        formData.append('key', url) // 存储在oss的文件路径
        formData.append('OSSAccessKeyId', this.policy.accessId) // accessKeyId
        formData.append('policy', this.policy.policy)   // policy
        formData.append('Signature', this.policy.signature) // 签名
        formData.append('file', file.file)
        formData.append('success_action_status', 200) // 成功后返回的操作码

        axios({
          url: this.policy.host,
          method: 'POST',
          data: formData,
          timeout: 300000
        }).then(data1 => {
          if (!data1) {
            this.$message.error('上传失败')
            return
          }
          if (data1.status && data1.status == 200) {
            this.firmWareForm.url = url
          } else {
            this.$message.error(data1)
          }
        }).catch(err => {
          this.$message.error(err)
        })
      }
    },

    // 上传文件
    handleBeforeUpload() {
      return true
    },
    handleSuccess() {
      this.$message.success('上传成功')
    },
    handleRemove() {
      this.firmWareForm.url = ''
    },
    handleExceed() {
      this.$message.error(`只能上传一个文件`);
    },

    submit() {
      this.$confirm('确认提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url: '/firmware/uploadFirm',
          method: 'post',
          headers: this.header,
          data: {
            version: this.firmWareForm.version,
            brief: this.firmWareForm.introduce,
            deviceType: this.firmWareForm.type,
            url: this.firmWareForm.url
          }
        }).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: "success",
              message: '上传成功'
            })
            this.firmWareForm = {}
            this.dialogVisible = false
          } else {
            this.$message.error(res.data.msg)
          }
        })
      })
    },

    updateVersion(list) {
      console.log(list)
      if(list.length === 0) {
        this.firmWareForm.version = 'V1.0'
      } else {
        // 获取最新的版本
        const newList = list.map(f => f.version).sort()
        const latestVersion = newList[newList.length - 1]
        let leftNum = latestVersion.charAt(1) - '0'
        let rightNum = latestVersion.charAt(3) - '0'
        if(rightNum === 9) {
          leftNum = leftNum + 1
          this.firmWareForm.version = 'V' + leftNum + '.0'
        } else {
          rightNum = rightNum + 1
          this.firmWareForm.version = 'V' + leftNum + '.' + rightNum
        }
      }
    }
  },
  mounted() {
    this.getFirmWareList()
    this.getSignature()
  }
}
</script>

<style scoped>

</style>
