//引入Vue核心库
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//应用Vuex插件
Vue.use(Vuex)
// 导入模块
import loginUser from './loginUser'
import patientsTable from "@/store/patientsTable";


//准备actions对象——响应组件中用户的动作
const actions = {
}
//准备mutations对象——修改state中的数据
const mutations = {
}
//准备state对象——保存具体的数据
const state = {
    users:[{
        uID:'0001',
        uName:'abc',
        uAccount:'abc',
        uPassword:'132465'
    }],
}

//创建并暴露store
export default new Vuex.Store({
    actions:actions,
    mutations:mutations,
    state:state,
    modules: {
        loginUser: loginUser,
        patientsTable: patientsTable,
    },
})






