import Vue from 'vue'
// 引入 vuex
import store from './store'
// 引入 element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
Vue.use(VueRouter)

import http from './http'
Vue.prototype.$http = http  // ajax请求方法

import url from './util/url.js'
Vue.prototype.$url = url

import {timeStampToString, dateToString} from './util/time'
Vue.prototype.$timeStampToString = timeStampToString
Vue.prototype.$dateToString = dateToString

// 引入组件
import App from './App.vue'

// 引入百度地图
import map from 'vue-baidu-map'
Vue.use(map, {ak: "https://api.map.baidu.com/api?v=1.0&&type=webgl&ak=P8TcGdSzHQl7kqGWDgDGHvtT3HiTnGIQ"})

// 引入md5
import md5 from "js-md5"
Vue.prototype.$md5 = md5

Vue.config.productionTip = false


new Vue({
  el:'#app',
  render: h => h(App),
  store: store,
  router: router,
  mounted() {
    console.log(this)
  }
})
