<template>
  <div id="container">
    <el-form id="form" :model="form" :rules="rules" ref="form" label-position="left" label-width="18%">

      <el-form-item label="新密码：" prop="newPassword">
        <el-input v-model="form.newPassword"></el-input>
      </el-form-item>

      <el-form-item label="确认密码：" prop="verifyPassword">
        <el-input v-model="form.verifyPassword"></el-input>
      </el-form-item>

      <el-form-item label="手机号：" prop="phoneNumber">
        <el-input v-model="form.phoneNumber" style="width: 60%"></el-input>
        <el-button type="primary" @click="sendCode" style="float: right; width: 30%">发送验证码</el-button>
      </el-form-item>

      <el-form-item label="验证码：">
        <el-input v-model="form.verifyCode" style="width: 60%"></el-input>
        <el-button type="primary" @click="onSubmit" style="float: right; width: 30%">确认修改</el-button>
      </el-form-item>

    </el-form>
  </div>

</template>

<script>
import axios from "axios";
import {checkPassword, checkPhone} from '../util/passwordUtil'

export default {
  name: "ForgetPassword",
  data() {
    // 保证修改后的密码不为空
    const checkNewPassword = (rule, value, callback) => {
      let res = checkPassword(value)
      if(res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }
    // 保证两次密码输入一致
    const checkVerifyPassword = (rule, value, callback) => {
      let res = checkPassword(value)
      if (value !== this.form.newPassword) {
        return callback(new Error('两次密码输入不一致'))
      } else if(res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }
    // 保证输入的手机号为11位
    const checkPhoneNumber = (rule, value, callback) => {
      let res = checkPhone(value)
      if (res.trim().length > 0) {
        return callback(new Error(res))
      } else {
        callback()
      }
    }

    return {
      form: {
        newPassword: '',
        verifyPassword: '',
        phoneNumber: '',
        verifyCode: '',
      },

      // 验证规则
      rules: {
        newPassword: [
          {validator: checkNewPassword, trigger: blur}
        ],
        verifyPassword: [
          {validator: checkVerifyPassword, trigger: blur}
        ],
        phoneNumber: [
          {validator: checkPhoneNumber, trigger: blur}
        ]
      }
    }
  },
  methods: {
    sendCode() {
      this.$refs.form.validate((valid) => {
        if(valid) {
          // 发送验证码
          axios({
            url: '/user/sendShortMessage',
            method: 'post',
            data: {
              phone: this.form.phoneNumber
            }
          }).then(response => {
            if(response.data.code === 200) {
              this.$message({
                type: "success",
                message: "发送成功"
              })
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          return false
        }
      })
    },

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid) {
          // 修改密码
          axios({
            url: '/user/forgetPassword',
            method: 'post',
            data: {
              phone: this.form.phoneNumber,
              password: this.$md5(this.form.newPassword),
              code: this.form.verifyCode
            }
          }).then(response => {
            if(response.data.code === 200) {
              this.$message({
                type: "success",
                message: "修改成功"
              })
              // 修改成功则跳转至登录界面
              this.$router.push({
                name: '/'
              })
            } else {
              this.$message({
                type: "error",
                message: response.data.msg
              })
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
#container {
  margin-top: 10%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#form {
  padding: 20px;
  border: solid #DCDFE6;
  border-radius: 4px;
  background-color: aliceblue;
  width: 30%;
}

</style>