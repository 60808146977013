<template>
  <el-menu router :default-active="$route.path" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
    <el-menu-item index="/homepage/map">
      <template slot="title">
        <i class="el-icon-map-location"></i>
        <span>地图</span>
      </template>
    </el-menu-item>

    <el-menu-item index="/homepage/statistic">
      <template slot="title">
        <i class="el-icon-s-data"></i>
        <span>统计</span>
      </template>
    </el-menu-item>

    <el-menu-item index="/homepage/useropeartion">
      <template slot="title">
        <i class="el-icon-document"></i>
        <span>用户管理</span>
      </template>
    </el-menu-item>

    <!-- <el-menu-item index="/homepage/loginmanage">
      <template slot="title">
        <i class="el-icon-user"></i>
        <span>冻结账户管理</span>
      </template>
    </el-menu-item> -->

    <!-- <el-menu-item index="/homepage/firmmanage" disabled>
      <template slot="title">
        <i class="el-icon-office-building"></i>
        <span>企业管理</span>
      </template>
    </el-menu-item> -->

    <el-menu-item v-if="userType == 5 || (permissions & 128) == 128" index="/homepage/powermanage">
      <template slot="title">
        <i class="el-icon-lock"></i>
        <span>角色管理</span>
      </template>
    </el-menu-item>

    <el-menu-item v-if="userType == 5 || (permissions & 4096) == 4096" index="/homepage/operationdialog">
      <template slot="title">
        <i class="el-icon-notebook-2"></i>
        <span>操作日志</span>
      </template>
    </el-menu-item>

    <el-menu-item v-if="userType == 5 || (permissions & 8192) == 8192" index="/homepage/goods">
      <template slot="title">
        <i class="el-icon-edit-outline"></i>
        <span>商品管理</span>
      </template>
    </el-menu-item>

    <el-menu-item v-if="userType == 5 || (permissions & 512) == 512" index="/homepage/firmwaremanage">
      <template slot="title">
        <i class="el-icon-edit-outline"></i>
        <span>软件发布</span>
      </template>
    </el-menu-item>

    <el-submenu index="news">
      <template slot="title">
        <i class="el-icon-edit"></i>
        <span>资讯</span>
      </template>
      <el-menu-item index="/homepage/textedit">
        <template slot="title">发布资讯</template>
      </el-menu-item>
      <el-menu-item index="/homepage/mynews">
        <template slot="title">我的资讯</template>
      </el-menu-item>
    </el-submenu>

    <el-menu-item v-if="userType > 3|| (permissions & 16384) == 16384" index="/homepage/newsmamage">
      <template slot="title">
        <i class="el-icon-document-checked"></i>
        <span>资讯审批</span>
      </template>
    </el-menu-item>

    <el-menu-item index="/homepage/userdata">
      <template slot="title">
        <i class="el-icon-postcard"></i>
        <span>个人信息</span>
      </template>
    </el-menu-item>

    <el-menu-item v-if="userType == 3" index="/homepage/medicalrecord">
      <template slot="title">
        <i class="el-icon-postcard"></i>
        <span>上传病历</span>
      </template>
    </el-menu-item>

    <!-- <el-menu-item index="9" disabled>
      <span>连接池监视</span>
    </el-menu-item> -->
  </el-menu>
</template>

<script>
export default {
  name: "MyAside",
  data() {
    return {
      userType: localStorage.getItem('userType'),
      permissions: localStorage.getItem('permissions')
    }
  },
  methods: {
    handleSubMenuOpen(index) {
      if (index === "goodsManage") {
        this.$router.push({
          name: 'goodslist'
        })
      } else if (index === "news") {
        this.$router.push({
          name: 'textedit'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>