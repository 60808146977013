<template>
  <el-card>
    <el-table :data="tree" row-key="label" border :tree-props="{children: 'children'}">
      <el-table-column prop="label" label="角色"></el-table-column>
    </el-table>    
  </el-card>

</template>

<script>
export default {
  name: "FirmManage",
  data() {
    return {
      tree: [{
        label: '超级管理员',
        children: [{
          label: '企业管理员',
          children: [{
            label: '企业普通用户',
            children: [{
              label: '软件运维人员',
              children: [{
                label: '医生',
                children: [{
                  label: '医生助理',
                  children: [{
                    label: '患者',
                    children: [{
                      label: '家属',
                    }]
                  }]
                }]
              }]
            }]
          }]
        }]
      }],

    }
  }
}
</script>

<style scoped>

</style>