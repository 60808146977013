<template>
  <div class="map">
    <el-card>
      <el-form inline>
        <!-- 选择省市区 -->
        <!-- <el-form-item label="地区： ">
          <el-cascader v-model="areaValue" :options="areaOptions" @change="handleAreaChange"></el-cascader>
        </el-form-item> -->
        <!-- 选择设备状态 -->
        <el-form-item label="设备类型： ">
          <el-select v-model="deviceType" clearable>
            <el-option
              v-for="option in deviceOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button @click="searchDevice" type="primary">查询设备</el-button>
        </el-form-item>
      </el-form>  
    </el-card>

    <el-card style="margin-top: 10px;">
      <baidu-map id="mapContainer" :center="center" :zoom="zoom" @ready="handler" scroll-wheel-zoom>
        <!-- <div v-for="(point, key) in points" :key="key">
          <bm-marker :position="{lng: point.lng, lat: point.lat}" @click="showInfoWindow(point)">
            <bm-info-window :show="point.show" @close="closeInfoWindow(point)">{{point.lng}}, {{point.lat}}</bm-info-window>
          </bm-marker>
        </div> -->
        <bm-point-collection :points="devices" color="red" size="BMAP_POINT_SIZE_SMALL" @click="clickHandler"></bm-point-collection>

        <!-- <div v-for="(point, key) in devices" :key="key">
          <bm-marker :position="{lng: point.lng, lat: point.lat}" @click="showInfoWindow(point)">
            <bm-info-window :show="point.show" @close="closeInfoWindow(point)">
              <el-descriptions title="设备信息" :column="3" border style="margin-block-start: 10px;">
                <el-descriptions-item  label="设备名称">{{ point.deviceName }}</el-descriptions-item>
                <el-descriptions-item  label="设备类型">{{ point.deviceType }}</el-descriptions-item>
                <el-descriptions-item  label="型号">{{ point.deviceModel }}</el-descriptions-item>
                <el-descriptions-item  label="版本">{{ point.deviceFirmwareEdition }}</el-descriptions-item>
                <el-descriptions-item  label="第一次激活时间">{{ point.registerTime }}</el-descriptions-item>
              </el-descriptions>
            </bm-info-window>
          </bm-marker>
        </div> -->
        <bm-marker
          v-for="device in devices"
          :key="device.id"
          :position="{ lng: device.devicePositionX, lat: device.devicePositionY }" 
          @click="showInfoWindow(device)">
            <el-descriptions title="设备信息" :column="3" border style="margin-block-start: 10px;">
              <el-descriptions-item  label="设备名称">{{ device.deviceName }}</el-descriptions-item>
              <el-descriptions-item  label="设备类型">{{ device.deviceType }}</el-descriptions-item>
              <el-descriptions-item  label="型号">{{ device.deviceModel }}</el-descriptions-item>
              <el-descriptions-item  label="版本">{{ device.deviceFirmwareEdition }}</el-descriptions-item>
              <el-descriptions-item  label="第一次激活时间">{{ device.registerTime }}</el-descriptions-item>
            </el-descriptions>
        </bm-marker>
      </baidu-map>      
    </el-card>

    <el-card style="margin-top: 10px;">
      <el-table :data="devices" border style="margin-top: 15px">
        <el-table-column label="设备名称" prop="deviceName" align="center" width="180px"/>
        <el-table-column label="设备类型" prop="deviceType" align="center" width="180px"/>
        <el-table-column label="设备型号" prop="deviceModel" align="center" width="180px"/>
        <el-table-column label="设备固件版本" prop="deviceFirmwareEdition" align="center" width="180px"/>
        <el-table-column label="第一次激活时间" prop="registerTime" align="center" width="180px"/>
      </el-table>
    </el-card>

    <el-dialog
      title="设备信息"
      :visible.sync="dialogVisible" 
      :before-close="beforeClose">
        <el-descriptions title="设备信息" :column="3" border style="margin-block-start: 10px;">
          <el-descriptions-item  label="设备名称">{{ deviceInfo.deviceName }}</el-descriptions-item>
          <el-descriptions-item  label="设备类型">{{ deviceInfo.deviceType }}</el-descriptions-item>
          <el-descriptions-item  label="型号">{{ deviceInfo.deviceModel }}</el-descriptions-item>
          <el-descriptions-item  label="版本">{{ deviceInfo.deviceFirmwareEdition }}</el-descriptions-item>
          <el-descriptions-item  label="第一次激活时间">{{ deviceInfo.registerTime }}</el-descriptions-item>
        </el-descriptions>     
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import areaData from '../statistic/pca-code.json'
import axios from "axios";

export default {
  name: "Map",
  data() {
    return {
      // 省市区数据
      areaOptions: areaData,
      areaValue: '',

      // 设备状态数据
      deviceOptions: [
        {value: '脑瘤设备', label: '脑瘤设备'},
        {value: '手环设备', label: '手环设备'}
      ],
      deviceType: '',

      // 地图数据
      center: {lng: 116.404, lat: 39.91},
      zoom: 3,
      points: [],

      devices: [],
      deviceInfo: {},
      dialogVisible: false
    }
  },
  computed: {
    ...mapState({locations:"locations"}),
  },
  methods: {
    // 地图函数
    // 在地图上添加设备
    addPoints() {
      const points = [];
      for(let i = 0; i < 100; i++) {
        points.push({lng: 116.404+Math.random(), lat: 39.91+Math.random(), show: false})
      }
      this.points = points;
    },
    // 点击点显示信息
    showInfoWindow(point) {
      this.deviceInfo = point
      this.dialogVisible = true
    },
    closeInfoWindow(point) {
      point.show = false;
    },
    // 地图初始化
    handler ({BMap, map}) {
      console.log(BMap, map)
      this.center.lng = 116.404
      this.center.lat = 39.915
      this.zoom = 15
      this.addPoints()
    },

    clickHandler (e) {
      this.deviceInfo = e
      this.dialogVisible = true
    },

    beforeClose() {
      this.deviceInfo = {}
      this.dialogVisible = false
    },

    // 表单函数
    handleAreaChange() {
      console.log(this.areaValue)
    },
    searchDevice() {
      let time = new Date().getTime().toString().slice(0, 10)
      axios({
        url: '/device/getDeviceLocation',
        method: 'post',
        headers: {
          "uid": localStorage.getItem('uid').toString(),
          "token": this.$md5(time + localStorage.getItem('token')),
          "time": time,
          "type": localStorage.getItem('userType').toString()
        },
        data: {
          deviceType: this.deviceType
        }
      }).then(async response => {
        if(response.data.code === 200) {
          this.devices = response.data.data
          this.devices.forEach(device => device.show = false)
          console.log(this.devices);
        } else {
          this.$message.error(response.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.searchDevice()
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: auto;
}

#mapContainer {
  width: 1300px;
  height: 700px;
  margin: 10px;
}
</style>